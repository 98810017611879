import React, { useState, useRef } from 'react';

const PatientHighlights = () => {
  const [displayedText, setDisplayedText] = useState('');
  const editedTextRef = useRef(null);

  const initialTextEnglish = `
    <h2>Brady Tom's Longitudinal Care Timeline.</h2>
   
    <div style="display: flex; align-items: flex-start; margin-bottom: 5px;">
      <b>Diabetes Type:</b>
    </div>
    <ul style="margin-left: 20px; margin-top: 0; padding-top: 0;">
      <li style="margin-bottom: 5px;"><b>Type:</b> T2DM</li>
    </ul>
    <div style="display: flex; align-items: flex-start; margin-bottom: 5px;">
      <b>Duration of Diabetes:</b>
    </div>
    <ul style="margin-left: 20px; margin-top: 0; padding-top: 0;">
      <li style="margin-bottom: 5px;"><b>Diagnosis Date:</b> January 15, 2023.</li>
      <li style="margin-bottom: 5px;"><b>Duration:</b> 1.5 years.</li>
    </ul>
    <div style="display: flex; align-items: flex-start; margin-bottom: 5px;">
      <b>Latest A1C Level:</b>
    </div>
    <ul style="margin-left: 20px; margin-top: 0; padding-top: 0;">
      <li style="margin-bottom: 5px;"><b>Current A1C:</b> 8.5%, previously 8.1% (December 10, 2023).</li>
    </ul>
    <div style="display: flex; align-items: flex-start; margin-bottom: 5px;">
      <b>Current Meds:</b>
    </div>
    <ul style="margin-left: 20px; margin-top: 0; padding-top: 0;">
      <li>Xigduo (2.5 mg/1000 mg ER) QAM</li>
      <li>Insulin: Adjust basal-bolus insulin regimen PRN</li>
      <li>Gabapentin: 300 mg QHS</li>
      <li>Lisinopril: 10 mg QD</li>
      <li>Atorvastatin: 40 mg QD</li>
      <li>Omega-3: 1.6g QD</li>
    </ul>
    <div style="display: flex; align-items: flex-start; margin-bottom: 5px;">
      <b>Recent Lab Results:</b>
    </div>
    <ul style="margin-left: 20px; margin-top: 0; padding-top: 0;">
      <li style="margin-bottom: 5px;"><b>Blood Glucose Levels:</b> 206 mg/dL, previously 183 mg/dL (bedtime reading)
      </li>
      <li style="margin-bottom: 5px;"><b> Kidney Function:</b>Serum creatinine: 1.4 mg/dL, eGFR: 55 mL/min/1.73m²
      </li>
    </ul>
    <div style="display: flex; align-items: flex-start; margin-bottom: 5px;">
      <b>Comorbid Conditions:</b>
    </div>
    <ul style="margin-left: 20px; margin-top: 0; padding-top: 0;">
      <li style="margin-bottom: 5px;"><b>Hypertension:</b> Mild LVH
      </li>
      <li style="margin-bottom: 5px;"><b>Peripheral Neuropathy:</b> Reduced sensation, bilateral feet
      </li>
      <li style="margin-bottom: 5px;"><b>Depression and Anxiety:</b> PHQ-9: Score of 8, GAD-7: Score of 5
      </li>
      <li style="margin-bottom: 5px;"><b>Nephropathy:</b> Stage 3 CKD
      </li>
    </ul>


    <div style="display: flex; align-items: flex-start; margin-bottom: 5px;">
      <b>Vital Signs:</b>
    </div>
    <ul style="margin-left: 20px; margin-top: 0; padding-top: 0;">
      <li style="margin-bottom: 5px;"><b>Blood Pressure:</b> 138/88 mmHg
      </li>
      <li style="margin-bottom: 5px;"><b>Pulse:</b> 80 bpm
      </li>
      <li style="margin-bottom: 5px;"><b>Weight and BMI:</b> 201 pounds, BMI 31.5 (obese)
      </li>
    </ul>

    <div style="display: flex; align-items: flex-start; margin-bottom: 5px;">
      <b>Insulin Regimen:</b>
    </div>
    <ul style="margin-left: 20px; margin-top: 0; padding-top: 0;">
      <li style="margin-bottom: 5px;"><b>Current Insulin Therapy:</b>NPH insulin, evening dose adjusted
      </li>
    </ul>


    <div style="display: flex; align-items: flex-start; margin-bottom: 5px;">
      <b>Care Summary:</b>
    </div>
    <ul style="margin-left: 20px; margin-top: 0; padding-top: 0;">
      <li style="margin-bottom: 5px;"><b>Urgent Care Visit:</b> Chest pain due to high blood pressure (February 10, 2023)
      </li>
      <li style="margin-bottom: 5px;"><b>Cardiology Assessment:</b> ECHO showing mild left ventricular hypertrophy (March 1, 2023)
      </li>
      <li style="margin-bottom: 5px;"><b>Nephrology Assessment:</b> Stage 3 CKD confirmed (May 17, 2023)
      </li>
      <li style="margin-bottom: 5px;"><b>Podiatry Visit:</b> Initial foot pain assessment (June 5, 2023)
      </li>
      <li style="margin-bottom: 5px;"><b>Hypoglycemia Episodes:</b> Monitor for signs with new insulin dose
      </li>
      <li style="margin-bottom: 5px;"><b>Lifestyle Factors:</b> Overwhelmed with diet management, starting gentle exercise routine
      </li>
      <li style="margin-bottom: 5px;"><b>Current Visit with Dr. Hsu:</b> Adjust insulin, consult dietitian, schedule eye exam (May 17, 2024)
      </li>
    </ul>
  `;

  const initialTextSpanish = `
    <p><b>Hola Brady, fue un placer verte hoy. Consultaste por entumecimiento en tu pie derecho y preocupaciones sobre manejar tu dieta y estrés.</b></p>
    <p>Aquí tienes el resumen de tu visita hoy:</p>
    <div style="display: flex; align-items: flex-start; margin-bottom: 5px;">
      <b>Para el entumecimiento y hormigueo en tu pie derecho:</b>
    </div>
    <ul style="margin-left: 20px; margin-top: 0; padding-top: 0;">
      <li style="margin-bottom: 5px;">Ajustar la dosis de insulina nocturna para manejar los niveles de glucosa durante la noche.</li>
      <li style="margin-bottom: 5px;">Continuar con Metformin y Xigduo según lo prescrito.</li>
    </ul>
    <div style="display: flex; align-items: flex-start; margin-bottom: 5px;">
      <b>Gestión de la dieta y el estrés:</b>
    </div>
    <ul style="margin-left: 20px; margin-top: 0; padding-top: 0;">
      <li style="margin-bottom: 5px;">Comenzar una rutina de caminata suave.</li>
      <li style="margin-bottom: 5px;">Consultar con un dietista para discutir un nuevo plan dietético.</li>
    </ul>
    <div style="display: flex; align-items: flex-start; margin-bottom: 5px;">
      <b>Recomendaciones adicionales:</b>
    </div>
    <ul style="margin-left: 20px; margin-top: 0; padding-top: 0;">
      <li style="margin-bottom: 5px;">Programar un examen ocular completo para evaluar los cambios recientes en la visión.</li>
    </ul>
    <div style="display: flex; align-items: flex-start; margin-bottom: 5px;">
      <b>Cuidados de seguimiento:</b>
    </div>
    <ul style="margin-left: 20px; margin-top: 0; padding-top: 0;">
      <li style="margin-bottom: 5px;">Programar una cita de seguimiento en 4 semanas para evaluar el progreso.</li>
      <li style="margin-bottom: 5px;">Ver al dietista en 2 semanas para comenzar tu nuevo plan dietético.</li>
      <li style="margin-bottom: 5px;">Monitorear el aumento de entumecimiento, hormigueo o cualquier síntoma nuevo.</li>
      <li style="margin-bottom: 5px;">Estar atento a los signos de hipoglucemia, como mareos, sudoración o confusión.</li>
    </ul>
    <div style="display: flex; align-items: flex-start; margin-bottom: 5px;">
      <b>atentamente,</b>
    </div>
    <div>
      <b>Dr Hsu</b>
    </div>
  `;

  const handleViewRecapEnglish = () => {
    setDisplayedText(initialTextEnglish);
  };

  const handleViewRecapSpanish = () => {
    setDisplayedText(initialTextSpanish);
  };

  const handleFinalizeRecap = () => {
    // Add your finalize logic here
  };

  return (
    <div>
      <br />
      <br />
      <h2 style={{ marginTop: '60px', color: 'gray', paddingLeft: '30px' }}>Patient Highlights</h2>
      <div style={{ padding: '0 30px' }}>
        <button onClick={handleViewRecapEnglish} style={{ 
            backgroundColor: '#1E90FF', 
            color: '#FFFFFF', 
            marginRight: '10px',
            height: '40px',
            padding: '10px 20px',
            borderRadius: '5px',
            border: 'none',
            cursor: 'pointer',
            fontSize: '16px',
            transition: 'background-color 0.3s ease'
          }}
          onMouseOver={(e) => e.currentTarget.style.backgroundColor = 'darkblue'}
          onMouseOut={(e) => e.currentTarget.style.backgroundColor = '#1E90FF'}
        >
          View Highlights
        </button>
      </div>
      <div style={{ padding: '0 30px' }}>
        <div
          contentEditable
          suppressContentEditableWarning
          onBlur={e => editedTextRef.current = e.target.innerHTML}
          style={{ whiteSpace: 'pre-line', paddingLeft: '1.5em', lineHeight: '1', marginTop: '20px' }}
          dangerouslySetInnerHTML={{ __html: displayedText }}
        />
        <button onClick={handleFinalizeRecap} style={{ 
            backgroundColor: '#FF6347', 
            color: '#FFFFFF', 
            marginRight: '10px',
            height: '40px',
            padding: '10px 20px',
            borderRadius: '5px',
            border: 'none',
            cursor: 'pointer',
            fontSize: '16px',
            transition: 'background-color 0.3s ease',
            marginTop: '20px'
          }}
          onMouseOver={(e) => e.currentTarget.style.backgroundColor = 'darkred'}
          onMouseOut={(e) => e.currentTarget.style.backgroundColor = '#FF6347'}
        >
          Finalize Highlights
        </button>
        <p style={{ whiteSpace: 'pre-line', paddingLeft: '1.5em', lineHeight: '1', marginTop: '20px' }}>{editedTextRef.current || 'No final recap available'}</p>
      </div>
    </div>
  );
}

export default PatientHighlights;
