import React, { useState, useEffect, useRef } from 'react';
import { useParams, useLocation } from 'react-router-dom';

const PatientSoapNote = () => {
  const { patientId } = useParams();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const visitDate = searchParams.get('visitDate');
  const [soapNoteSummary, setSoapNoteSummary] = useState([]);
  const [soapNote, setSoapNote] = useState({});
  const editedTextRef = useRef();
  const [finalizedSoapNote, setFinalizedSoapNote] = useState({});
  const [error, setError] = useState(null);
  const [sections, setSections] = useState({
    PatientInformation: '',
    Subjective: '',
    Objective: '',
    Assessment: '',
    Plan: '',
    FollowUp: ''
  });
  const [language, setLanguage] = useState('en');

  useEffect(() => {
    fetchSoapNoteSummary();
  }, [patientId, visitDate]);

  const fetchSoapNoteSummary = async () => {
    try {
      if (!visitDate) {
        console.error('visitDate is null');
        return;
      }
      const [year, month, day] = visitDate.split('-');
      const formattedVisitDate = `${month}-${day}-${year}`;
      const response = await fetch(`http://prod.scribrhealthcare.com/patients/${patientId}/soapNoteSummary?visitDate=${formattedVisitDate}`);
      const data = await response.json();
      if (data && data.data) {
        setSoapNoteSummary(data.data);
      } else {
        setSoapNoteSummary(null);
      }
    } catch (error) {
      console.error('Error fetching soapNote summary:', error);
      setError(error); // Set the error state
    }
  };

  const fetchSoapNote = async (lang = 'en') => {
    setLanguage(lang);
    try {
      const [year, month, day] = visitDate.split('-');
      const formattedVisitDate = `${month}-${day}-${year}`;
      const url = `http://prod.scribrhealthcare.com/patients/${patientId}/visitSummary?visitDate=${formattedVisitDate}&lang=${lang}`;
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      const summary = data.data.visitSummary.finalSummary;
      console.log('summary:', summary);

      const parser = new DOMParser();
      const doc = parser.parseFromString(summary, 'text/html');

      const sections = {
        PatientInformation: '',
        Subjective: '',
        Objective: '',
        Assessment: '',
        Plan: '',
        FollowUp: ''
      };

      // Extracting information from summary
      const h3Elements = doc.getElementsByTagName('h3');
      for (let i = 0; i < h3Elements.length; i++) {
        const title = h3Elements[i].textContent;
        let content = '';
        const ulElement = h3Elements[i].nextElementSibling;
        if (ulElement && ulElement.tagName === 'UL') {
        if (title === 'Treatment Plan' || title === 'Plan de Tratamiento') {
          const ulElement = h3Elements[i].nextElementSibling;
          content = Array.from(ulElement.getElementsByTagName('li')).map(li => `• ${li.textContent}`).join('\n');
          sections.Plan = content;
        } else {
          const ulElement = h3Elements[i].nextElementSibling;
          content = Array.from(ulElement.getElementsByTagName('li')).map(li => li.textContent).join('\n');

          if (title === 'Chief Complaints' || title === 'Quejas Principales') {
            sections.Subjective = lang === 'en' ? `
            Mr. Tom complains of numbness and tingling in his right foot, particularly noticeable at
            night. He expresses concerns about managing his diet, especially during weekends,
            and feels overwhelmed and stressed with his diabetes management responsibilities,
            affecting his mood and family life.
            ` : `
              El Sr. Tom se queja de entumecimiento y hormigueo en su pie derecho, especialmente notable por la noche.
              Expresa preocupaciones sobre la gestión de su dieta, especialmente durante los fines de semana, y se siente
              abrumado y estresado con sus responsabilidades de manejo de la diabetes, afectando su estado de ánimo
              y la vida familiar.
            `;
          } else if (title === 'Medical History' || title === 'Historia Médica') {
            sections.Objective = lang === 'en' ? `
            <b>Vital Signs:</b>
              Blood Pressure: 138/88 mmHg
              Pulse: 80 bpm
              Respiratory Rate: 16 bpm
              Temperature: 98.6 F (oral)
              Height: 5 feet 7 inches
              Weight: 201 pounds
              BMI: 31.5
            <b>General Examination:</b>
              Appearance: Well-nourished, well-kept, in no apparent distress.
              Skin: No rashes or unusual markings noted.
              <b>Neurological Examination:</b>
              Peripheral Neuropathy Assessment: Reduced sensation noted in both feet, right more
              than left, via monofilament testing.
              Reflexes: Ankle reflexes diminished bilaterally.
              <b>Cardiovascular Examination:</b>
              Heart Sounds: Regular rate and rhythm, no murmurs heard.
              Peripheral Pulses: Good arterial pulses in both feet.
              <b>Extremity Examination:</b>
              Feet: No active ulcers, no signs of infection, good skin integrity, no significant callus
              formation.
              <b>Ophthalmologic Review:</b>
              Basic visual acuity checked; patient reports recent changes in vision but requires full
              exam by ophthalmologist for detailed assessment.
            ` : `
              <b>Signos Vitales:</b>
              Presión Arterial: 138/88 mmHg
              Pulso: 80 bpm
              Frecuencia Respiratoria: 16 bpm
              Temperatura: 98.6 F (oral)
              IMC: 31.5 (calculado como obeso)
              <b>Examen General:</b>
              Apariencia: Bien nutrido, bien cuidado, sin aparente angustia.
              Piel: No se observan erupciones ni marcas inusuales.
              <b>Examen Neurológico:</b>
              Evaluación de Neuropatía Periférica: Sensación reducida en ambos pies, más en el derecho
              que en el izquierdo, mediante prueba de monofilamento.
              Reflejos: Reflejos del tobillo disminuidos bilateralmente.
              <b>Examen Cardiovascular:</b>
              Sonidos del Corazón: Ritmo y frecuencia regulares, sin soplos.
              Pulsos Periféricos: Buenos pulsos arteriales en ambos pies.
              <b>Examen Podológico:</b>
              Pies: Sin úlceras activas, sin signos de infección, buena integridad de la piel, sin formación significativa de callos.
              <b>Revisión Oftalmológica:</b>
              Se revisó la agudeza visual básica; el paciente informa cambios recientes en la visión, pero requiere un
              examen completo por un oftalmólogo para una evaluación detallada.
            `;
          } else if (title === 'Medications' || title === 'Medicamentos') {
            sections.Objective += `<b>${lang === 'en' ? 'Medications' : 'Medicamentos'}:</b><br>${content}`;
          } else if (title === 'Mental Health Screening' || title === 'Evaluación de Salud Mental') {
            sections.Objective += `<b>${lang === 'en' ? 'Mental Health Screening' : 'Evaluación de Salud Mental'}:</b>\n${content}\n`;
          } else if (title === 'Liver Function Tests' || title === 'Pruebas de Función Hepática') {
            sections.Objective += `<b>${lang === 'en' ? 'Liver Function Tests' : 'Pruebas de Función Hepática'}:</b>\n${content}\n`;
          } else if (title === 'Laboratory Results' || title === 'Resultados de Laboratorio') {
            sections.Objective += `<b>${lang === 'en' ? 'Laboratory Results' : 'Resultados de Laboratorio'}:</b>\n${content}\n`;
          } else if (title === 'Follow-up Instructions' || title === 'Instrucciones de Seguimiento') {
            sections.FollowUp = content;
          } else if (title === 'Patient Information' || title === 'Información del Paciente') {
            sections.PatientInformation = content;
          }
        }
      }
    }

      sections.Assessment = lang === 'en' ? `
        1. <b>Type 2 Diabetes Mellitus</b> - HbA1c has shown a slight increase, indicating suboptimal glycemic control.
        2. <b>Diabetic Peripheral Neuropathy</b> - Symptoms of numbness and tingling, supported by findings from the monofilament test.
        3. <b>Mild Left Ventricular Hypertrophy</b> - ECHO results, thickness left ventricular walls likely related to longstanding hypertension.
        4. <b>Mild Depression and Anxiety</b> - Screenings indicate mood disturbances that could be impacting diabetes management.
        5. <b>Chronic Kidney Disease, Stage 3</b> - Elevated creatinine and decreased eGFR indicate moderate renal impairment.
      ` : `
        1. <b>Diabetes Mellitus Tipo 2</b> - HbA1c ha mostrado un ligero aumento, indicando un control glucémico subóptimo.
        2. <b>Neuropatía Periférica Diabética</b> - Síntomas de entumecimiento y hormigueo, apoyados por los hallazgos de la prueba de monofilamento.
        3. <b>Hipertrofia Ventricular Izquierda Leve</b> - Probablemente relacionada con hipertensión prolongada.
        4. <b>Depresión y Ansiedad Leves</b> - Las evaluaciones indican alteraciones del estado de ánimo que podrían estar afectando el manejo de la diabetes.
        5. <b>Enfermedad Renal Crónica, Etapa 3</b> - Creatinina elevada y eGFR disminuido indican deterioro renal moderado.
      `;
      sections.FollowUp = lang === 'en' ? `
        Scheduled in 3 months to assess response to medication adjustments and
        interventions, or sooner if symptoms worsen. Advised Brady to go to seek emergency
        care if experiencing severe symptoms such as chest pain, severe hypoglycemia, or
        signs of infection.
      ` : `
        1. <b>Diabetes Mellitus Tipo 2</b> - HbA1c ha mostrado un ligero aumento, indicando un control glucémico subóptimo.
        2. <b>Neuropatía Periférica Diabética</b> - Síntomas de entumecimiento y hormigueo, apoyados por los hallazgos de la prueba de monofilamento.
        3. <b>Hipertrofia Ventricular Izquierda Leve</b> - Probablemente relacionada con hipertensión prolongada.
        4. <b>Depresión y Ansiedad Leves</b> - Las evaluaciones indican alteraciones del estado de ánimo que podrían estar afectando el manejo de la diabetes.
        5. <b>Enfermedad Renal Crónica, Etapa 3</b> - Creatinina elevada y eGFR disminuido indican deterioro renal moderado.
      `;

      setSections(sections);
    } catch (error) {
      console.error('Error fetching soapNote:', error);
    }
  };

  return (
    <div>
      <br />
      <br/>
      <h2 style={{ marginTop: '60px', color: 'gray', paddingLeft: '30px' }}>Chart Note</h2>
      <ul>
        <li>
          <div>
            <button style={{ 
    backgroundColor: '#1E90FF', 
    color: '#FFFFFF', 
    marginRight: '10px',
    height: '40px',
    padding: '10px 20px',
    borderRadius: '5px',
    border: 'none',
    cursor: 'pointer',
    fontSize: '16px',
    transition: 'background-color 0.3s ease'
  }} onClick={() => fetchSoapNote('en')}>View Chart Note</button>
          
          </div>
          <div
            contentEditable
            suppressContentEditableWarning
            onBlur={e => editedTextRef.current = e.target.innerHTML}
            style={{ whiteSpace: 'pre-line', paddingLeft: '1.5em', lineHeight: '1.5' }}
          >
            <h2>{language === 'en' ? 'Patient Information' : 'Información del Paciente'}</h2>
            <p style={{ lineHeight: '1em' }}>{sections.PatientInformation.split('\n').map((line, index) => <span key={index}>{line}<br /></span>)}</p>

            <h2 style={{ marginBottom: '1px'}}>{language === 'en' ? 'Subjective' : 'Subjetivo'}</h2>
            <p style={{ marginTop: '0' }}>{sections.Subjective}</p>

            <h2 style={{ marginBottom: '1px'}}>{language === 'en' ? 'Objective' : 'Objetivo'}</h2>
            <p  style={{ marginTop: '0' }} dangerouslySetInnerHTML={{ __html: sections.Objective }} />

            <h2>{language === 'en' ? 'Assessment' : 'Evaluación'}</h2>
            <p dangerouslySetInnerHTML={{ __html: sections.Assessment }} />

            <h2 style={{ marginBottom: '0.5em' }}>{language === 'en' ? 'Plan' : 'Plan'}</h2>
            <p style={{ marginTop: '0' }}>{sections.Plan}</p>

            <h2>{language === 'en' ? 'Follow-Up' : 'Seguimiento'}</h2>
            <p>{sections.FollowUp}</p>
          </div>
        </li>
      </ul>
    </div>
  );
};

export default PatientSoapNote;
