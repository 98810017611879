import React, { useState, useEffect } from 'react';
import { Grid, Box, Button, List, ListItem, ListItemIcon, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import ArrowForwardIos from '@mui/icons-material/ArrowForwardIos';
import { useNavigate } from 'react-router-dom';
import PatientForm from './PatientForm';

const PatientDashboard = ({patients, setPatients}) => {
  const [selectedLink, setSelectedLink] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [content, setContent] = useState('');
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  //const [patients, setPatients] = useState([]);



  const handleOpen = () => {
    setIsModalOpen(true);
  };

  const handleAddPatient = (patient) => {
    fetch('http://prod.scribrhealthcare.com/patients/add', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(patient),
    })
    .then(response => response.json())
    .then(data => {
      console.log(data);
      setPatients(prevPatients => [...prevPatients, data]);
      handleClose();
    })
    .catch(error => {
      console.error('Error:', error);
    });
  };

  const handleClose = () => {
    setIsModalOpen(false);
  };

//  const handleAddPatientClick = () => {
//
//    history.push('/add-patient');
//  };
  const handleLinkClick = (link) => {
    setSelectedLink(link);
    setContent(content);
  };
  const handleRowClick = (patientId) => {
       navigate(`/patient-detail/${patientId}`);
    };
  const filteredPatients = patients.filter(patient =>
      patient.firstName.toLowerCase().includes(searchTerm.toLowerCase()) ||
      patient.lastName.toLowerCase().includes(searchTerm.toLowerCase())
    );

  return (
    <Grid container spacing={3} style={{ marginTop: '64px', backgroundColor: 'F5F5F5' }}>
      <Grid item xs={2} style={{ borderRight: '0px solid #D3D3ED',paddingRight: '10px' }}>
        <Box display="flex" justifyContent="space-between">
        <Button onClick={handleOpen} variant="contained" color="primary" style={{ whiteSpace: 'nowrap', borderRadius: '12px' }}>Add Patient</Button>
        {isModalOpen && <PatientForm onClose={handleClose} onAddPatient={handleAddPatient} />}
        </Box>

        
      </Grid>
      <Grid item xs={10} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>

                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell align="right">Age</TableCell>
                        <TableCell align="right">Last Visit Date</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {filteredPatients.map((patient) => (
                        <TableRow key={patient.patientId} onClick={() => handleRowClick(patient.patientId)}>
                          <TableCell component="th" scope="row">
                            {patient.firstName + ' ' + patient.lastName}
                          </TableCell>
                          <TableCell align="right">{patient.age}</TableCell>
                          <TableCell align="right">{patient.visitDate[patient.visitDate.length-1]}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>

              {selectedLink && (
                 <Grid container spacing={2}>
                            <Grid item xs={12}>
                              <Typography variant="h6">{selectedLink}</Typography>
                              <Typography>{content}</Typography> {/* Display the content here */}
                            </Grid>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
        );
      };

      export default PatientDashboard;