import React, { useState } from 'react';
import { Container, Grid, Typography, Box, Button, Paper } from '@mui/material';
import { useNavigate } from 'react-router-dom'; // Import useNavigate for navigation
import LocalPharmacyIcon from '@mui/icons-material/LocalPharmacy';
import HealthAndSafetyIcon from '@mui/icons-material/HealthAndSafety';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import ContactFormDrawer from './ContactFormDrawer';

const PreventADEPage = () => {
    const [drawerOpen, setDrawerOpen] = useState(false);
    const navigate = useNavigate(); // Initialize useNavigate

    const handleOpenDrawer = () => {
        setDrawerOpen(true);
    };

    const handleCloseDrawer = () => {
        setDrawerOpen(false);
    };

    const handleRedirectToAdePrediction = () => {
        navigate('/ade-prediction'); // Redirect to AdePrediction page
    };

    return (
        <Container maxWidth="lg" style={{ marginTop: '40px' }}>
            <Grid container spacing={4}>
                {/* Introduction Section */}
                <Grid item xs={12}>
                    <Typography variant="h4" align="center" color="textPrimary" gutterBottom>
                        Preventing Adverse Drug Events and Reducing Payor Costs
                    </Typography>
                    <Typography variant="body1" align="center" color="textSecondary" paragraph>
                        Adverse Drug Events (ADEs) are a significant concern in healthcare, leading to patient harm, increased hospital stays, and higher costs for payors. Our solution aims to mitigate these risks and reduce overall healthcare costs by leveraging advanced analytics and proactive intervention.
                    </Typography>
                </Grid>

                {/* Impact of ADEs Section */}
                <Grid item xs={12}>
                    <Paper elevation={3} style={{ padding: '20px', backgroundColor: '#f9f9f9' }}>
                        <Typography variant="h6" color="textPrimary" gutterBottom>
                            The Impact of ADEs
                        </Typography>
                        <Typography variant="body1" color="textSecondary" paragraph>
                            - <strong>Over 2 million:</strong> ADEs occur each year in the United States, leading to thousands of hospitalizations and deaths.
                        </Typography>
                        <Typography variant="body1" color="textSecondary" paragraph>
                            - <strong>$136 billion:</strong> Estimated annual costs associated with ADEs, making it one of the most significant cost burdens in healthcare.
                        </Typography>
                        <Typography variant="body1" color="textSecondary" paragraph>
                            - <strong>15%:</strong> of hospital costs can be attributed to ADEs, increasing the financial burden on payors and patients.
                        </Typography>
                    </Paper>
                </Grid>

                {/* Solution Overview Section */}
                <Grid item xs={12} md={6}>
                    <Typography variant="h5" color="textPrimary" gutterBottom>
                        Our Solution: Proactive ADE Prevention
                    </Typography>
                    <Typography variant="body1" color="textSecondary" paragraph>
                        Our solution uses cutting-edge technology to monitor and analyze patient data in real-time, identifying potential ADE risks before they occur. By integrating with existing healthcare systems, we provide actionable insights that enable healthcare providers to intervene early and prevent ADEs, thereby reducing hospitalizations and associated costs.
                    </Typography>
                    <Button variant="contained" color="primary" size="large" style={{ marginTop: '20px' }} onClick={handleRedirectToAdePrediction}>
                        Test ADE and Save Costs
                    </Button>
                </Grid>

                {/* Benefits Section */}
                <Grid item xs={12} md={6}>
                    <Typography variant="h5" color="textPrimary" gutterBottom>
                        Benefits for Healthcare Providers and Payors
                    </Typography>
                    <Box display="flex" alignItems="center" mb={2}>
                        <HealthAndSafetyIcon color="primary" style={{ fontSize: 40, marginRight: 16 }} />
                        <Typography variant="body1">
                            <strong>Enhanced Patient Safety:</strong> Proactively reduce the risk of ADEs, leading to better patient outcomes.
                        </Typography>
                    </Box>
                    <Box display="flex" alignItems="center" mb={2}>
                        <AttachMoneyIcon color="primary" style={{ fontSize: 40, marginRight: 16 }} />
                        <Typography variant="body1">
                            <strong>Cost Savings:</strong> Decrease hospital readmissions and treatment costs, benefiting both providers and payors.
                        </Typography>
                    </Box>
                    <Box display="flex" alignItems="center" mb={2}>
                        <LocalPharmacyIcon color="primary" style={{ fontSize: 40, marginRight: 16 }} />
                        <Typography variant="body1">
                            <strong>Compliance and Reporting:</strong> Improve compliance with safety regulations and streamline reporting processes.
                        </Typography>
                    </Box>
                </Grid>
            </Grid>

            {/* Call to Action Section */}
            <Box mt={4} textAlign="center">
                <Typography variant="h6" color="textPrimary" gutterBottom>
                    Ready to Reduce Costs and Enhance Patient Safety?
                </Typography>
                <Typography variant="body1" color="textSecondary" paragraph>
                    Contact us today to learn more about our solution and how it can help your organization prevent ADEs and reduce costs.
                </Typography>
                <Button variant="contained" color="secondary" size="large" onClick={handleOpenDrawer}>
                    Get in Touch
                </Button>
            </Box>
            <ContactFormDrawer open={drawerOpen} onClose={handleCloseDrawer} />
        </Container>
    );
};

export default PreventADEPage;
