// PatientForm.js
import React, { useState } from 'react';

const PatientForm = ({onClose, onAddPatient}) => {
  const [patient, setPatient] = useState({ name: '', age: '', email: '', occupation: '', symptoms: ''});

  const handleChange = (e) => {
    setPatient({ ...patient, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    onAddPatient(patient);
  };

  return (
    <div style={{
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 1000,
    }}>
    <form onSubmit={handleSubmit} style={{
        backgroundColor: 'white',
        padding: '20px',
        borderRadius: '12px',
        width: '50%',
        height: '50%',
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
      }}>
       <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <input type="text" name="name" value={patient.name} onChange={handleChange} placeholder="Name" required style={{ flex: 2, marginRight: '10px' }} />
          <input type="number" name="age" value={patient.age} onChange={handleChange} placeholder="Age" required style={{ flex: 1 }} />
        </div>
        <input type="text" name="email" value={patient.email} onChange={handleChange} placeholder="Email" required />
        <input type="text" name="occupation" value={patient.occupation} onChange={handleChange} placeholder="Occupation" required />
        <textarea name="symptoms" value={patient.symptoms} onChange={handleChange} placeholder="Symptoms" required style={{ height: '100px' }} />
        <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
          <button type="button" onClick={onClose} style={{ backgroundColor: 'gray', color: 'white', border: 'none', padding: '10px', borderRadius: '4px', marginRight: '10px' }}>Cancel</button>
          <button type="submit" style={{ backgroundColor: 'blue', color: 'white', border: 'none', padding: '10px', borderRadius: '4px' }}>Submit</button>
        </div>
    </form>
    </div>
  );
};

export default PatientForm;