import React from 'react';
import { AppBar, Toolbar, Typography, Button, IconButton, Avatar, Box, Menu, MenuItem,  useMediaQuery, useTheme, TextField, InputAdornment } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import logo from '../assets/diligent.png'; // adjust the path according to your file structure
import user from '../assets/user.jpeg'; // adjust the path according to your file structure
function Header({ searchTerm, setSearchTerm, setPatients }) {
    const [anchorElFeatures, setAnchorElFeatures] = useState(null);
    const [anchorElUseCases, setAnchorElUseCases] = useState(null);
  const theme = useTheme();
   const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

   const handleClickFeatures = (event) => {
    setAnchorElFeatures(event.currentTarget);
   }
   const handleCloseFeatures = () => {
    setAnchorElFeatures(null);
   }

   const handleClickUseCases = (event) => {
    setAnchorElUseCases(event.currentTarget);
   }
   const handleCloseUseCases = () => {
    setAnchorElUseCases(null);
   }

    const handleSearchChange = (event) => {
      setSearchTerm(event.target.value);
    };

      const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
          fetch(`http://prod.scribrhealthcare.com/patients/search?name=${searchTerm}`)
            .then(response => response.json())
            .then(data => setPatients([data.data])); // Set the searched patient data
        }
      };

 return (
         <AppBar position="fixed" sx={{ bgcolor: '#FFFFFF' }}>
             <Toolbar style={{ display: 'flex', justifyContent: 'space-between' }}>
             <Box display="flex" alignItems="center">
                 {isMobile && (
                     <IconButton edge="start" color="inherit" aria-label="menu">
                         <MenuIcon />
                     </IconButton>
                 )}
                 <img src={logo} alt="Company Logo" height="40" style={{ marginRight: '10px' }} />
                 <Typography variant="h6" component="div" sx={{ flexGrow: 1 }} style={{ color: 'black' }}>
                 </Typography>
              </Box>
                 <TextField
                     variant="outlined"
                     margin="normal"
                     id="search"
                     label="Search Patients"
                     name="search"
                     autoComplete="search"
                     autoFocus
                     value={searchTerm}
                     onChange={handleSearchChange}
                     onKeyDown={handleKeyDown}
                     style={{ width: '40%', backgroundColor: 'white', borderRadius: '20px' }}
                         InputProps={{
                             startAdornment: (
                                 <InputAdornment position="start">
                                     <SearchIcon />
                                 </InputAdornment>
                             ),
                         }}
                         sx={{
                             '& .MuiOutlinedInput-root': {
                                 '& fieldset': {
                                     border: 'none',
                                 },
                                 '&:hover fieldset': {
                                     border: 'none',
                                 },
                                 '&.Mui-focused fieldset': {
                                     border: 'none',
                                 },
                             },
                         }}
                     />

                 <Box mr={2}>
                     <Typography variant="h6" component="div" style={{ color: 'black' }}>
                         Shelly Kemmerer MD
                     </Typography>
                 </Box>
                 <Avatar alt="User Image" src={user} />
             </Toolbar>
         </AppBar>
     );
 }

 export default Header;