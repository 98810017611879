// SignIn.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppBar, Toolbar, Typography, Grid, TextField, Button, Checkbox, FormControlLabel, Box } from '@mui/material';
import { Link } from 'react-router-dom';
import logo from '../assets/diligent.png';

function SignIn({ setIsLoggedIn }) {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleSubmit = (event) => {
    event.preventDefault();

    if (username === 'shelley@gmail.com' && password === 'shc123@') {
      setIsLoggedIn(true);
      navigate('/dashboard');
    } else {
      alert('Invalid username or password');
    }
  };

  return (
    <div style={{backgroundColor: '#F5F5F5'}}>
    <header style={{ display: 'flex', justifyContent: 'space-between', padding: '10px', alignItems: 'center', backgroundColor: '#FFFFFF',position: 'fixed', width: '100%', zIndex: 1000}}>
      <img src={logo} alt="Company Logo" height="40" style={{ marginRight: '10px' }} />

      <div>
        <a href="/about-us" style={{ marginRight: '20px',  textDecoration: 'none', fontWeight: '500', color:'#635f5f' }}>About Us</a>
        <a href="/contact-us" style={{ marginRight: '20px', textDecoration: 'none', fontWeight: '500', color:'#635f5f' }}>Contact Us</a>
        <Button variant="contained" color="primary" href="/sign-in" style={{ marginRight: '20px' }}>Sign In</Button>

      </div>
    </header>
      <Toolbar />
      <Grid container>
        <Grid item xs={12} md={6}>
          <img src="https://scribr-assets.s3.us-west-2.amazonaws.com/signin.jpg" alt="Sign In" style={{ width: '100%', height: 'auto' }} />
        </Grid>
        <Grid item xs={12} md={6}>
          <Box p={2}>
            
              <Typography variant="h4">Sign in to your account</Typography>
            
            <Typography variant="subtitle1">Welcome back! Select method to sign in</Typography>
            <form onSubmit={handleSubmit}>
              <TextField label="Email" value={username} onChange={(e) => setUsername(e.target.value)} fullWidth />
              <TextField label="Password" type="password" value={password} onChange={(e) => setPassword(e.target.value)} fullWidth />
              <FormControlLabel control={<Checkbox name="remember" />} label="Remember me" />
              <Button type="submit" variant="contained" color="primary">Sign In</Button>
            </form>
            <Typography>Don't have an account? <Link to="/signup">Sign Up</Link></Typography>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
}

export default SignIn;