import React from 'react';
import { Container, Grid, Card, CardContent, Typography, Button, Box } from '@mui/material';

const PricingPage = () => {
    return (
        <Container maxWidth="lg" style={{ marginTop: '40px' }}>
            <Typography variant="h4" align="center" color="textPrimary" gutterBottom>
                Choose the Plan That's Right for You
            </Typography>
            <Typography variant="body1" align="center" color="textSecondary" paragraph>
                Our plans are designed to fit the unique needs of healthcare providers and patients. Select the one that best suits your practice and patient care goals.
            </Typography>

            <Grid container spacing={4} justifyContent="center">
                {/* Basic Plan */}
                <Grid item xs={12} md={4}>
                    <Card variant="outlined">
                        <CardContent>
                            <Typography variant="h5" align="center" color="primary" gutterBottom>
                                Basic
                            </Typography>
                            <Typography variant="body1" align="center" color="textSecondary" paragraph>
                                Ideal for patients seeking personalized care and long-term health forecasts.
                            </Typography>
                            <Typography variant="body2" color="textSecondary" paragraph>
                                - Personalized treatment plans
                            </Typography>
                            <Typography variant="body2" color="textSecondary" paragraph>
                                - Long-term health forecasts
                            </Typography>
                            <Typography variant="body2" color="textSecondary" paragraph>
                                - Diabetes Predictor (For Patients only)
                            </Typography>
                            <Box display="flex" justifyContent="center" mt={2}>
                                <Button variant="contained" color="primary">Choose Basic</Button>
                            </Box>
                        </CardContent>
                    </Card>
                </Grid>

                {/* Premium Plan */}
                <Grid item xs={12} md={4}>
                    <Card variant="outlined" style={{ border: '2px solid #3f51b5' }}>
                        <CardContent>
                            <Typography variant="h5" align="center" color="primary" gutterBottom>
                                Premium
                            </Typography>
                            <Typography variant="body1" align="center" color="textSecondary" paragraph>
                                Perfect for healthcare providers who need comprehensive patient management and predictive tools.
                            </Typography>
                            <Typography variant="body2" color="textSecondary" paragraph>
                                - EHR Integration
                            </Typography>
                            <Typography variant="body2" color="textSecondary" paragraph>
                                - Disease progression likelihood
                            </Typography>
                            <Typography variant="body2" color="textSecondary" paragraph>
                                - Recommended interventions
                            </Typography>
                            <Typography variant="body2" color="textSecondary" paragraph>
                                - Predictive health alerts
                            </Typography>
                            <Typography variant="body2" color="textSecondary" paragraph>
                                - Compliance monitoring
                            </Typography>
                            <Box display="flex" justifyContent="center" mt={2}>
                                <Button variant="contained" color="primary">Choose Premium</Button>
                            </Box>
                        </CardContent>
                    </Card>
                </Grid>

                {/* Platinum Plan */}
                <Grid item xs={12} md={4}>
                    <Card variant="outlined">
                        <CardContent>
                            <Typography variant="h5" align="center" color="primary" gutterBottom>
                                Platinum
                            </Typography>
                            <Typography variant="body1" align="center" color="textSecondary" paragraph>
                                The ultimate plan for advanced healthcare providers focusing on risk management and in-depth analysis.
                            </Typography>
                            <Typography variant="body2" color="textSecondary" paragraph>
                                - All features of the Premium plan
                            </Typography>
                            <Typography variant="body2" color="textSecondary" paragraph>
                                - Risk assessment scores
                            </Typography>
                            <Box display="flex" justifyContent="center" mt={2}>
                                <Button variant="contained" color="primary">Choose Platinum</Button>
                            </Box>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Container>
    );
};

export default PricingPage;
