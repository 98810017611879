import React from 'react';
import { Container, Typography, Box, Button, Grid } from '@mui/material';
import ScienceIcon from '@mui/icons-material/Science';
import SecurityIcon from '@mui/icons-material/Security';

const GenomicTestingPage = () => {
    return (
        <Container maxWidth="lg" style={{ marginTop: '40px' }}>
            <Typography variant="h4" align="center" color="textPrimary" gutterBottom>
                Genomic Testing
            </Typography>
            <Typography variant="body1" align="center" color="textSecondary" paragraph>
                Leverage the power of genomic testing to tailor your treatment plans. Our partnership with leading third-party providers ensures accurate and reliable results, helping you prevent adverse drug events and provide personalized care.
            </Typography>
            
            <Grid container spacing={4} style={{ marginTop: '20px' }}>
                <Grid item xs={12} md={6}>
                    <ScienceIcon color="primary" style={{ fontSize: 80, marginBottom: '20px' }} />
                    <Typography variant="h5" color="textPrimary" gutterBottom>
                        Cutting-Edge Genomic Testing
                    </Typography>
                    <Typography variant="body1" color="textSecondary" paragraph>
                        Our collaboration with top-tier genomic testing providers allows us to offer state-of-the-art genomic analysis that helps tailor treatments based on individual genetic profiles.
                    </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                    <SecurityIcon color="primary" style={{ fontSize: 80, marginBottom: '20px' }} />
                    <Typography variant="h5" color="textPrimary" gutterBottom>
                        Prevent Adverse Drug Events
                    </Typography>
                    <Typography variant="body1" color="textSecondary" paragraph>
                        By understanding your genetic makeup, we can recommend the most effective and safe treatment options, significantly reducing the risk of adverse drug events.
                    </Typography>
                </Grid>
            </Grid>

            <Box textAlign="center" marginTop="40px">
                <Button variant="contained" color="primary" size="large">
                    Get Started
                </Button>
            </Box>
        </Container>
    );
};

export default GenomicTestingPage;
