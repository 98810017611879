import { useEffect, useState } from 'react';
import DOMPurify from 'dompurify'; // Assuming you've installed DOMPurify
import { format } from 'date-fns';
import { useLocation, useParams } from 'react-router-dom';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

// const PatientRecap = () => {
//   const [displayedText, setDisplayedText] = useState('');
//   const editedTextRef = useRef(null);

//   const initialTextEnglish = `
//     <p><b>Hi Brady, it was great to see you today. You were seen for numbness in your right foot and concerns about managing your diet and stress.</b></p>
//     <p>Here is the Recap of your visit today:</p>
//     <div style="display: flex; align-items: flex-start; margin-bottom: 5px;">
//       <b>For numbness and tingling in your right foot:</b>
//     </div>
//     <ul style="margin-left: 20px; margin-top: 0; padding-top: 0;">
//       <li style="margin-bottom: 5px;">Start low-dose Gabapentin: 300 mg, take at bedtime.</li>
//       <li style="margin-bottom: 5px;">Increase Xigduo to 5 mg/1000 mg ER, take in the morning.</li>
//     </ul>
//     <div style="display: flex; align-items: flex-start; margin-bottom: 5px;">
//       <b>Diet and stress management:</b>
//     </div>
//     <ul style="margin-left: 20px; margin-top: 0; padding-top: 0;">
//       <li style="margin-bottom: 5px;">Begin a gentle walking routine.</li>
//       <li style="margin-bottom: 5px;">See the dietitian in 2 weeks to start your new dietary plan.</li>
//     </ul>
//     <div style="display: flex; align-items: flex-start; margin-bottom: 5px;">
//       <b>Additional recommendations:</b>
//     </div>
//     <ul style="margin-left: 20px; margin-top: 0; padding-top: 0;">
//       <li style="margin-bottom: 5px;">Schedule a full eye exam to assess recent changes in vision.</li>
//       <li style="margin-bottom: 5px;">Refer to a psychologist for management of mood and stress.</li>
//     </ul>
//     <div style="display: flex; align-items: flex-start; margin-bottom: 5px;">
//       <b>Follow-up care:</b>
//     </div>
//     <ul style="margin-left: 20px; margin-top: 0; padding-top: 0;">
//       <li style="margin-bottom: 5px;">Book a follow-up with me in 3 months to assess progress, or sooner if symptoms worsen.</li>
//       <li style="margin-bottom: 5px;">Monitor for increased numbness, tingling, or any new symptoms.</li>
//       <li style="margin-bottom: 5px;">Watch for signs of hypoglycemia, such as dizziness, sweating, or confusion.</li>
//     </ul>
//    <div>
//     If your symptoms worsen or you experience severe hypoglycemia, chest pain, or signs of infection, go to the nearest emergency department or call 911.
// Enjoy your family time, and don’t hesitate to reach out if you need support.
// </div>
//  <div>
//  <br/>
//  <br/>
//       <b>Best Regards,</b>
//     </div>
//     <div>
//       <b>Dr Hsu</b>
//     </div>
//   `;

//   const initialTextSpanish = `
//     <p><b>Hola Brady, fue genial verte hoy. Lo atendieron por entumecimiento en el pie derecho y preocupaciones sobre el manejo de su dieta y el estrés.</b></p>
//     <p>Aquí tienes el resumen de tu visita hoy:</p>
//     <div style="display: flex; align-items: flex-start; margin-bottom: 5px;">
//       <b>Para el entumecimiento y hormigueo en tu pie derecho:</b>
//     </div>
//     <ul style="margin-left: 20px; margin-top: 0; padding-top: 0;">
//       <li style="margin-bottom: 5px;">Ajustar la dosis de insulina nocturna para manejar los niveles de glucosa durante la noche.</li>
//       <li style="margin-bottom: 5px;">Continuar con Metformin y Xigduo según lo prescrito.</li>
//     </ul>
//     <div style="display: flex; align-items: flex-start; margin-bottom: 5px;">
//       <b>Gestión de la dieta y el estrés:</b>
//     </div>
//     <ul style="margin-left: 20px; margin-top: 0; padding-top: 0;">
//       <li style="margin-bottom: 5px;">Comience una rutina de caminata suave.</li>
//       <li style="margin-bottom: 5px;">Consulte al dietista en 2 semanas para comenzar su nuevo plan dietético.</li>
//     </ul>
//     <div style="display: flex; align-items: flex-start; margin-bottom: 5px;">
//       <b>Recomendaciones adicionales:</b>
//     </div>
//     <ul style="margin-left: 20px; margin-top: 0; padding-top: 0;">
//       <li style="margin-bottom: 5px;">Programar un examen ocular completo para evaluar los cambios recientes en la visión.</li>
//     </ul>
//     <div style="display: flex; align-items: flex-start; margin-bottom: 5px;">
//       <b>Cuidados de seguimiento:</b>
//     </div>
//     <ul style="margin-left: 20px; margin-top: 0; padding-top: 0;">
//       <li style="margin-bottom: 5px;">Reserve un seguimiento conmigo en 3 meses para evaluar el progreso, o antes si los síntomas empeoran.</li>
//       <li style="margin-bottom: 5px;">Ver al dietista en 2 semanas para comenzar tu nuevo plan dietético.</li>
//       <li style="margin-bottom: 5px;">Monitorear el aumento de entumecimiento, hormigueo o cualquier síntoma nuevo.</li>
//       <li style="margin-bottom: 5px;">Estar atento a los signos de hipoglucemia, como mareos, sudoración o confusión.</li>
//     </ul>
//     <div>
//     Si sus síntomas empeoran o experimenta hipoglucemia grave, dolor en el pecho o signos de infección, vaya al departamento de emergencias más cercano o llame al 911.
//     Disfrute de su tiempo en familia y no dude en comunicarse si necesita ayuda.
//     </div>
//     <div style="display: flex; align-items: flex-start; margin-bottom: 5px;">
//       <b>atentamente,</b>
//     </div>
//     <div>
//       <b>Dr Hsu</b>
//     </div>
//   `;

//   const handleViewRecapEnglish = () => {
//     setDisplayedText(initialTextEnglish);
//   };

//   const handleViewRecapSpanish = () => {
//     setDisplayedText(initialTextSpanish);
//   };

//   const handleFinalizeRecap = () => {
//     // Add your finalize logic here
//   };

//   return (
//     <div>
//       <br />
//       <h2 style={{ marginTop: '60px', color: 'gray', paddingLeft: '30px' }}>Patient Recap</h2>
//       <div style={{ padding: '0 30px' }}>
//         <button onClick={handleViewRecapEnglish} style={{ 
//             backgroundColor: '#1E90FF', 
//             color: '#FFFFFF', 
//             marginRight: '10px',
//             height: '40px',
//             padding: '10px 20px',
//             borderRadius: '5px',
//             border: 'none',
//             cursor: 'pointer',
//             fontSize: '16px',
//             transition: 'background-color 0.3s ease'
//           }}
//           onMouseOver={(e) => e.currentTarget.style.backgroundColor = 'darkblue'}
//           onMouseOut={(e) => e.currentTarget.style.backgroundColor = '#1E90FF'}
//         >
//           View Recap in English
//         </button>
//         <button onClick={handleViewRecapSpanish} style={{ 
//             backgroundColor: '#2F4F4F', 
//             color: '#FFFFFF', 
//             marginRight: '10px',
//             height: '40px',
//             padding: '10px 20px',
//             borderRadius: '5px',
//             border: 'none',
//             cursor: 'pointer',
//             fontSize: '16px',
//             transition: 'background-color 0.3s ease'
//           }}
//           onMouseOver={(e) => e.currentTarget.style.backgroundColor = 'darkgreen'}
//           onMouseOut={(e) => e.currentTarget.style.backgroundColor = '#2F4F4F'}
//         >
//           Ver Resumen en Español
//         </button>
//       </div>
//       <div style={{ padding: '0 30px' }}>
//         <div
//           contentEditable
//           suppressContentEditableWarning
//           onBlur={e => editedTextRef.current = e.target.innerHTML}
//           style={{ whiteSpace: 'pre-line', paddingLeft: '1.5em', lineHeight: '1', marginTop: '20px' }}
//           dangerouslySetInnerHTML={{ __html: displayedText }}
//         />
//         <button onClick={handleFinalizeRecap} style={{ 
//             backgroundColor: '#FF6347', 
//             color: '#FFFFFF', 
//             marginRight: '10px',
//             height: '40px',
//             padding: '10px 20px',
//             borderRadius: '5px',
//             border: 'none',
//             cursor: 'pointer',
//             fontSize: '16px',
//             transition: 'background-color 0.3s ease',
//             marginTop: '20px'
//           }}
//           onMouseOver={(e) => e.currentTarget.style.backgroundColor = 'darkred'}
//           onMouseOut={(e) => e.currentTarget.style.backgroundColor = '#FF6347'}
//         >
//           Finalize Recap
//         </button>
//         <p style={{ whiteSpace: 'pre-line', paddingLeft: '1.5em', lineHeight: '1', marginTop: '20px' }}>{editedTextRef.current || 'No final recap available'}</p>
//       </div>
//     </div>
//   );
// }

const PatientRecap = () => {
  const { patientId } = useParams();
  const query = useQuery();
  const visitDate = query.get('visitDate');
  const [recapData, setRecapData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchRecapData = async () => {
      setLoading(true);
      const apiUrl = `https://prod.scribrhealthcare.com/patients/${patientId}/recap?visitDate=${visitDate}`;
      console.log(`Patient ID: ${patientId}, Visit Date: ${visitDate}`);
      console.log('API URL:', apiUrl);
      try {
        const response = await fetch(apiUrl);
        if (!response.ok) {
          throw new Error('Failed to fetch recap data');
        }
        // Check if the response content type is JSON
        const contentType = response.headers.get('content-type');
        console.log('Content-Type:', contentType); // Temporarily log the content-type
  
        if (!contentType || !contentType.includes('application/json')) {
          // Instead of throwing an error, log a warning and attempt to parse the response as JSON
          console.warn('Expected JSON response but received non-JSON response, attempting to parse as JSON');
        }
        const data = await response.json(); // Attempt to parse response as JSON regardless of content-type
        setRecapData(data.data);
      } catch (error) {
        console.error('Fetch operation error:', error);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };
  
    fetchRecapData();
  }, [patientId, visitDate]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  const sanitizedSummary = recapData ? DOMPurify.sanitize(recapData.replace(/\n/g, '<br />')) : '';
  return (
    <div>
      <h1>Patient Recap</h1>
      {recapData && (
        <div>
          <p>Date: {visitDate ? format(new Date(visitDate), 'PPP') : 'N/A'}</p>
          <div dangerouslySetInnerHTML={{ __html: sanitizedSummary }} />
        </div>
      )}
    </div>
  );
};

export default PatientRecap;
