// src/App.js
import { React, useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import ContactUs from './ContactUs';
import AboutUs from './AboutUs';
import HowItWorks from './HowItWorks';
import PatientDashboard from './components/PatientDashboard';
import PatientDetail from './components/PatientDetail';
import VisitSummaryPage from './components/VisitSummaryPage'; // Import the VisitSummaryPage component
import PatientRecap from './components/PatientRecap'; // Import the PatientRecap component
import PatientHighlights from './components/PatientHighlights';
import PatientSoapNote from './components/PatientSoapNote';
import HomePage from './components/HomePage';
import SignIn from './components/SignIn';
import TermsAndConditions from './TermsAndConditions';
import DiabetesForm from './components/DiabetesForm'; 
import ScribrAI from './components/ScribrAI';
import PreventADEPage from './components/PreventADEPage'; // Import the PreventADEPage component
import PricingPage from './components/PricingPage';
import GenomicTestingPage from './components/GenomicTestingPage';
import InboxManagementPage from './components/InboxManagementPage';
import AdePrediction from './components/AdePrediction'; // Import the AdePrediction component

function App() {
  const [patients, setPatients] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [isLoggedIn, setIsLoggedIn] = useState(false); // Add this line

  useEffect(() => {
    fetch('https://prod.scribrhealthcare.com/patients/search/all')
      .then(response => response.json())
      .then(data => setPatients(data.data)); // Set the patients data
  }, []);

  return (
    <div>
      <Router>
        {isLoggedIn && <Header searchTerm={searchTerm} setSearchTerm={setSearchTerm} setPatients={setPatients} />}
        <Routes>
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/about-us" element={<AboutUs />} /> 
          <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
          <Route path="/how-it-works" element={<HowItWorks />} />
          <Route path="patient-detail/:id" element={<PatientDetail />} />
          <Route path="/patients/:patientId/visitSummary" element={<VisitSummaryPage />} /> {/* Add this route */}
          <Route path="/" element={<HomePage />} />
          <Route path="/dashboard" element={<PatientDashboard patients={patients} />} />
          <Route path="/sign-in" element={<SignIn setIsLoggedIn={setIsLoggedIn} />} /> {/* Add this route */}
          <Route path="/patient-recap/:patientId" element={<PatientRecap />} />
          <Route path="/patient-highlights/:patientId" element={<PatientHighlights />} />
          <Route path="/patient-soapnote/:patientId" element={<PatientSoapNote />} />
          <Route path="/diabetes-predictor" element={<DiabetesForm />} />
          <Route path="/scribr" element={<ScribrAI />} />
          <Route path="/ade" element={<PreventADEPage />} />
          <Route path="/pricing" element={<PricingPage />} />
         <Route path="/genomic-testing" element={<GenomicTestingPage />} />
         <Route path="/inbox-management" element={<InboxManagementPage />} />
         <Route path="/ade-prediction" element={<AdePrediction />} />
        </Routes>

      </Router>
    </div>
  );
}

export default App;