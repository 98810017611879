import React, { useState, useEffect, useRef } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, IconButton } from '@mui/material';
import { useParams,useNavigate } from 'react-router-dom';
import { ReactMic } from 'react-mic';
import axios from 'axios';
import './PatientDetail.css';

const PatientDetail = () => {
  const [patient, setPatient] = useState(null);
  const[record, setRecord] = useState(false);
  const recordingData = useRef(null);
  const [recordings, setRecordings] = useState([]);
  const recordingProcessing = useRef(false);
  const navigate = useNavigate();
  const chunks = useRef([]);

  const { id } = useParams();
  //const mediaRecorderRef = useRef(null);

  useEffect(() => {
    const fetchPatientData = async () => {
      try {
        const response = await axios.get(`https://prod.scribrhealthcare.com/patients/${id}`);
        if (response.ok) {
          const data = await response.data;
          setPatient(data);
          setRecordings(data.recordings || []); // Set initial recordings
        } else {
          console.error('Failed to fetch patient data');
        }
      } catch (error) {
        console.error('Error fetching patient data:', error);
      }
    };
    fetchPatient();
  }, [id]);

  const fetchPatient = async () => {
    try {
      const response = await fetch(`https://prod.scribrhealthcare.com/patients/${id}`);
      if (response.ok) {
        const data = await response.json();
        setPatient(data.data);
      } else {
        console.error('Failed to fetch patient data');
      }
    } catch (error) {
      console.error('Error fetching patient data:', error);
    }
  };


  const startRecording = () => {
    setRecord(true);
   // recordingData.current = null; // Clear previous recording data
    chunks.current = []; 
  };

  const stopRecording = async () => {
    if (recordingProcessing.current) return; // If already processing, prevent duplicate calls
    recordingProcessing.current = true; // Set processing state
    setRecord(false);
   // if (!recordingData.current) return;

    const blob = new Blob(chunks.current, { type: 'audio/webm' });
    const buffer = await blob.arrayBuffer();

    // Log ArrayBuffer contents (optional)
    console.log(new Uint8Array(buffer));

    const formData = new FormData();
    formData.append('recording', blob);
    formData.append('patientId', id);

    try {
      const response = await axios.post('https://prod.scribrhealthcare.com/patients/recordings', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });

      if (response.status === 200) {
        console.log('Recording saved successfully');
        setRecordings([...recordings, response.data.recordingUrl]);
        // Handle successful recording (optional: clear recording data)
      } else {
        console.error('Failed to save recording:', response.statusText);
      }
    } catch (error) {
      console.error('Error saving recording:', error);
    }finally {
      recordingProcessing.current = false; // Reset processing state after API call
    }
  };

  const fetchRecordingSummary = async (recordingUrl) => {
    try {
      const response = await axios.get(`https://prod.scribrhealthcare.com/audio/summary`, {
        params: {
          filePath: recordingUrl // Assuming the API endpoint expects a filePath param
        }
      });

      if (response.status === 200) {
        const summary = response.data;
        console.log('Recording summary:', summary);
        // Update recordings state to include summary for the specific recording
      //  setRecordings(recordings.map(url => url === recordingUrl ? { url, summary } : url));
      } else {
        console.error('Failed to fetch recording summary:', response.statusText);
      }
    } catch (error) {
      console.error('Error fetching recording summary:', error);
    }
  };

  const handlePlayback = (recordingUrl) => {
    // ... logic to play audio using recordingUrl
  };
  const handleVisitSummary = (patientId, visitDate) => {
    // Format the visitDate to match the format expected by the API
    const formattedVisitDate = new Date(visitDate).toISOString().split('T')[0];
    navigate(`/patients/${patientId}/visitSummary?visitDate=${formattedVisitDate}`); // Navigate to visit summary page
};

const handleRecapSummary = (patientId, visitDate) => {
  console.log("recap method called");
  console.log('Patient ID:', patientId);
  console.log('visitDate:', visitDate);
  const date = new Date(visitDate);
  const formattedMonth = (date.getMonth() + 1).toString().padStart(2, '0');
  const formattedDay = date.getDate().toString().padStart(2, '0');
  const formattedYear = date.getFullYear();
  const formattedVisitDate = `${formattedMonth}-${formattedDay}-${formattedYear}`;
  navigate(`/patient-recap/${patientId}?visitDate=${formattedVisitDate}`);
};

const handleHighlights = (patientId, date) => {
  console.log("highlights method called");
  console.log('Patient ID:', patientId);
  navigate(`/patient-highlights/${patientId}?date=${date}`);
};

const createSoapNote = (patientId, visitDate) => {
  console.log("soap note method called");
  console.log('Patient ID:', patientId);
  const formattedVisitDate = new Date(visitDate).toISOString().split('T')[0];
  navigate(`/patient-soapnote/${patientId}?visitDate=${formattedVisitDate}`);
};

  function isSameOrBeforeDate(url, dateString) {
    // Implement logic to extract date from URL (assuming YYYY/MM/DD format)
    const urlDate = new Date(url.slice(/* extract date portion from URL */)); // Adjust slice arguments based on your URL format
    const visitDate = new Date(dateString);
    return urlDate.getTime() <= visitDate.getTime(); // Check if URL date is on or before visit date
  }

  function decodeBase64(data) {
    // Option 1: Using built-in function (if available)
    if (typeof window !== 'undefined' && window.atob) {
      return atob(data);
    }
  
    // Option 2: Using js-base64 library
    const Base64 = require('js-base64').Base64;
    return Base64.decode(data);
  }

  return (
    <div style={{backgroundColor: 'F5F5F5'}}>
      {patient ? (
        <div>
          <h2>Patient Details</h2>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell style={{ fontWeight: 600 }}>Name: {`${patient.firstName} ${patient.lastName}`}</TableCell>
                  <TableCell style={{ fontWeight: 600 }}>Age: {patient.age}</TableCell>
                  <TableCell>
                  <div style={{ display: 'none', width: '100%'}}>
                  <ReactMic
                      record={record}
                      onStop={stopRecording} // Call stopRecording when recording stops
                      onData={(chunk) => chunks.current.push(chunk)} // Push audio chunks to the ref
                    />
                    </div>
                    <Button variant="contained" color="primary" onClick={startRecording} disabled={record}>
                      Start Recording
                    </Button>
                    <Button variant="contained" color="error" onClick={stopRecording} disabled={!record}>
                    Stop Recording
                    </Button>
                  </TableCell>
                </TableRow>
               
                <TableRow>
                  <TableCell style={{ fontWeight: 'bold' }}>Visit Date</TableCell>
                  <TableCell style={{ fontWeight: 'bold'}}>Summary</TableCell>
                  <TableCell style={{ fontWeight: 'bold' }}>Patient Recap</TableCell>
                  <TableCell style={{ fontWeight: 'bold' }}>Patient Highlights</TableCell>
                  <TableCell style={{ fontWeight: 'bold' }}>Chart Note</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
  {patient && patient.visitDate && patient.visitDate.length > 0 && patient.visitDate.map((date, index) => (
    <TableRow key={index}>
      {/* <TableCell>{new Date(date).toLocaleDateString()}</TableCell> */}
      <TableCell>{new Date(date).toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' })}</TableCell>

      <TableCell>
        {/* View Summary Button */}
        <Button variant="text" onClick={() => handleVisitSummary(patient.patientId, date)}>
          View Summary
        </Button>
      </TableCell>
      <TableCell>
        {/* View Summary Button */}
        <Button variant="text" onClick={() => handleRecapSummary(patient.patientId, date)}>
          View Recap
        </Button>
      </TableCell>
      <TableCell>
        {/* View Summary Button */}
        <Button variant="text" onClick={() => handleHighlights(patient.patientId, date)}>
          View Highlights
        </Button>
      </TableCell>
      <TableCell>
        {/* View Summary Button */}
        <Button variant="text" onClick={() => createSoapNote(patient.patientId, date)}>
          View Chart Note
        </Button>
      </TableCell>
    </TableRow>
  ))}
</TableBody>

            </Table>
          </TableContainer>
        </div>
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
};

export default PatientDetail;
