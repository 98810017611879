import React, { useState } from 'react';
import { TextField, Button, Select, MenuItem, FormControl, InputLabel, Typography, Grid, Container, Box, Paper, Tooltip, Alert } from '@mui/material';
import axios from 'axios';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import healthy from '../assets/healthy_life.png';

const DiabetesForm = ({ onSubmit }) => {
    const [formData, setFormData] = useState({
        HighBP: 0,
        HighChol: 0,
        CholCheck: 0,
        BMI: 0,
        Smoker: 0,
        Stroke: 0,
        HeartDiseaseorAttack: 0,
        PhysActivity: 0,
        Fruits: 0,
        Veggies: 0,
        HvyAlcoholConsump: 0,
        AnyHealthcare: 0,
        NoDocbcCost: 0,
        GenHlth: 3,
        MentHlth: 0,
        PhysHlth: 0,
        DiffWalk: 0,
        Sex: 0,
        Age: 1,
        Education: 1,
        Income: 1
    });
    const [email, setEmail] = useState('');
    const [showForm, setShowForm] = useState(false);
    const [apiResponse, setApiResponse] = useState(null); // State to store the API response

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleProceed = async () => {
        if (!email.trim()) { // Check if email is empty or only contains whitespace
            setApiResponse('Email address is required.'); // Set error message
            return; // Exit the function early
        }
    
        try {
            // Call API to save email
            const response = await axios.post('https://prod.scribrhealthcare.com/scribr/profile/saveEmail', { email });
            if (response.status === 200) {
                setShowForm(true); // Show form on successful API call (200 OK)
                setApiResponse(null); // Clear any previous error messages
            } else {
                // Handle non-200 responses
                console.error('Received non-200 response:', response.status);
                setApiResponse(`Received non-200 response: ${response.status}`); // Optionally, set error message
            }
        } catch (error) {
            console.error('Error saving email:', error);
            setApiResponse('Error saving email: ' + error.message); // Set error message
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        // Convert binary and numeric values to integers
        const convertedData = { ...formData };
        Object.keys(convertedData).forEach(key => {
            convertedData[key] = parseInt(convertedData[key], 10);
        });
    
        try {
            // Make the POST request to the API
            const response = await axios.post('https://prod.scribrhealthcare.com/patients/predict', convertedData);
    
            // Handle the API response
            setApiResponse(response.data);
    
            // Optionally, you can do additional things with the response here
        } catch (error) {
            console.error('There was an error submitting the form!', error);
            // Handle the error (e.g., display an error message)
        }
    };

    return (
        <Container maxWidth="lg">
        <Typography variant="h4" align="center" color="textSecondary" gutterBottom>
            Diabetes Predictor
        </Typography>
        {/* <Typography variant="body1" align="center" color="textSecondary" gutterBottom>
            Our Diabetes Predictor tool helps assess your risk of developing diabetes based on your lifestyle and health factors.
        </Typography> */}

        {!showForm ? (
            <Box display="flex" flexDirection="column" alignItems="center">
                {apiResponse && (
                    <Alert severity="error" style={{ marginBottom: '20px' }}>
                        {apiResponse}
                    </Alert>
                )}
                <Box width="100%" maxWidth="400px">
                <TextField
                    type="email"
                    label="Enter email address"
                    value={email}
                    onChange={handleEmailChange}
                    variant="outlined"
                    fullWidth
                    margin="normal"
                />
                </Box>
                <Button variant="contained" color="primary" onClick={handleProceed}>
                    Proceed
                </Button>
            </Box>
        ) : (
            <Grid container spacing={4}>
                <Grid item xs={12} md={6}>
                    {/* Infographic Section */}
                    <Box>
                        {/* <Typography variant="h5" color="textPrimary" gutterBottom>
                            What Our Diabetes Predictor Does
                        </Typography> */}
                        <Typography variant="body1" color="textSecondary" gutterBottom>
                            Understand your risk of developing diabetes with our easy-to-use tool. Based on your lifestyle and health data, we provide:
                        </Typography>
                        <Box display="flex" alignItems="center" mb={2}>
                            <CheckCircleOutlineIcon color="primary" style={{ fontSize: 40, marginRight: 16 }} />
                            <Typography variant="body1">
                                A clear prediction of whether you are at risk of diabetes or not.
                            </Typography>
                        </Box>
                        <Box display="flex" alignItems="center" mb={2}>
                            <FitnessCenterIcon color="primary" style={{ fontSize: 40, marginRight: 16 }} />
                            <Typography variant="body1">
                                Personalized lifestyle recommendations to help you maintain a healthy life.
                            </Typography>
                        </Box>
                        <Box display="flex" alignItems="center" mb={2}>
                            <LocalHospitalIcon color="primary" style={{ fontSize: 40, marginRight: 16 }} />
                            <Typography variant="body1">
                                Advice on regular screening and preventive measures to avoid diabetes.
                            </Typography>
                        </Box>
                        <Box mt={4}>
                            <img src={healthy} alt="Healthy Lifestyle" style={{ width: '100%', height: 'auto' }} />
                        </Box>

                        {apiResponse && (
                <Box mt={2}>
                    <Paper elevation={3} style={{ padding: '16px' }}>
                        <Typography variant="h6">Prediction Result</Typography>
                        <Typography variant="body1">
                            Probability: {apiResponse.probability}%
                        </Typography>
                        <Typography variant="body1">
                            Prediction: {apiResponse.prediction}
                        </Typography>
                        <Typography variant="h6" style={{ marginTop: '16px' }}>Recommendations</Typography>
                        <Typography variant="body2">
                            <strong>Lifestyle Maintenance:</strong> {apiResponse.recommendation.LifestyleMaintenance}
                        </Typography>
                        <Typography variant="body2">
                            <strong>Regular Screening:</strong> {apiResponse.recommendation.RegularScreening}
                        </Typography>
                        <Typography variant="body2">
                            <strong>Preventive Measures:</strong> {apiResponse.recommendation.PreventiveMeasures}
                        </Typography>
                    </Paper>
                </Box>
            )}
                    </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                <Box mt={3}>
                    <form onSubmit={handleSubmit}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <FormControl fullWidth variant="outlined">
                                    <InputLabel>High Blood Pressure</InputLabel>
                                    <Select
                                        name="HighBP"
                                        value={formData.HighBP}
                                        onChange={handleChange}
                                        label="High Blood Pressure"
                                    >
                                        <MenuItem value={0}>No</MenuItem>
                                        <MenuItem value={1}>Yes</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl fullWidth variant="outlined">
                                    <InputLabel>High Cholesterol</InputLabel>
                                    <Select
                                        name="HighChol"
                                        value={formData.HighChol}
                                        onChange={handleChange}
                                        label="High Cholesterol"
                                    >
                                        <MenuItem value={0}>No</MenuItem>
                                        <MenuItem value={1}>Yes</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl fullWidth variant="outlined">
                                    <InputLabel>Cholesterol Checked in Last 5 Years</InputLabel>
                                    <Select
                                        name="CholCheck"
                                        value={formData.CholCheck}
                                        onChange={handleChange}
                                        label="Cholesterol Checked in Last 5 Years"
                                    >
                                        <MenuItem value={0}>No</MenuItem>
                                        <MenuItem value={1}>Yes</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    label="Body Mass Index (BMI)"
                                    type="number"
                                    name="BMI"
                                    value={formData.BMI}
                                    onChange={handleChange}
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl fullWidth variant="outlined">
                                    <InputLabel>Smoker</InputLabel>
                                    <Select
                                        name="Smoker"
                                        value={formData.Smoker}
                                        onChange={handleChange}
                                        label="Smoker"
                                    >
                                        <MenuItem value={0}>No</MenuItem>
                                        <MenuItem value={1}>Yes</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl fullWidth variant="outlined">
                                    <InputLabel>Have you ever had a stroke?</InputLabel>
                                    <Select
                                        name="Stroke"
                                        value={formData.Stroke}
                                        onChange={handleChange}
                                        label="Have you ever had a stroke?"
                                    >
                                        <MenuItem value={0}>No</MenuItem>
                                        <MenuItem value={1}>Yes</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12}>
                                <FormControl fullWidth variant="outlined">
                                    <InputLabel>Heart Attack or Coronary Heart Disease?</InputLabel>
                                    <Select
                                        name="HeartDiseaseorAttack"
                                        value={formData.HeartDiseaseorAttack}
                                        onChange={handleChange}
                                        label="Heart Attack or Coronary Heart Disease?"
                                    >
                                        <MenuItem value={0}>No</MenuItem>
                                        <MenuItem value={1}>Yes</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12}>
                                <FormControl fullWidth variant="outlined">
                                    <InputLabel>Physical Activities in Last 30 Days?</InputLabel>
                                    <Select
                                        name="PhysActivity"
                                        value={formData.PhysActivity}
                                        onChange={handleChange}
                                        label="Physical Activities in Last 30 Days?"
                                    >
                                        <MenuItem value={0}>No</MenuItem>
                                        <MenuItem value={1}>Yes</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl fullWidth variant="outlined">
                                    <InputLabel>Do you consume fruit at least once per day?</InputLabel>
                                    <Select
                                        name="Fruits"
                                        value={formData.Fruits}
                                        onChange={handleChange}
                                        label="Do you consume fruit at least once per day?"
                                    >
                                        <MenuItem value={0}>No</MenuItem>
                                        <MenuItem value={1}>Yes</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12}>
                                <FormControl fullWidth variant="outlined">
                                    <InputLabel>Do you consume vegetables at least once per day?</InputLabel>
                                    <Select
                                        name="Veggies"
                                        value={formData.Veggies}
                                        onChange={handleChange}
                                        label="Do you consume vegetables at least once per day?"
                                    >
                                        <MenuItem value={0}>No</MenuItem>
                                        <MenuItem value={1}>Yes</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12}>
                                <FormControl fullWidth variant="outlined">
                                    <Tooltip title="Do you consume more than 14 drinks per week (for men) or more than 7 drinks per week (for women)?">
                                    <InputLabel>Do you consume more than 14 drinks per week (for men) or more than 7 drinks per week (for women)?</InputLabel>
                                    <Select
                                        name="HvyAlcoholConsump"
                                        value={formData.HvyAlcoholConsump}
                                        onChange={handleChange}
                                        label="Do you consume more than 14 drinks per week (for men) or more than 7 drinks per week (for women)?"
                                    >
                                        <MenuItem value={0}>No</MenuItem>
                                        <MenuItem value={1}>Yes</MenuItem> 
                                    </Select>
                                    </Tooltip>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12}>
                                <FormControl fullWidth variant="outlined">
                                    <InputLabel>Do you have any kind of health care coverage?</InputLabel>
                                    <Select
                                        name="AnyHealthcare"
                                        value={formData.AnyHealthcare}
                                        onChange={handleChange}
                                        label="Do you have any kind of health care coverage?"
                                    >
                                        <MenuItem value={0}>No</MenuItem>
                                        <MenuItem value={1}>Yes</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12}>
                                <FormControl fullWidth variant="outlined">
                                <Tooltip title="Was there a time in the past 12 months when you needed to see a doctor but could not because of cost?" arrow>
                                    <InputLabel>Was there a time in the past 12 months when you needed to see a doctor but could not because of cost?</InputLabel>
                                    <Select
                                        name="NoDocbcCost"
                                        value={formData.NoDocbcCost}
                                        onChange={handleChange}
                                        label="Was there a time in the past 12 months when you needed to see a doctor but could not because of cost?"
                                    >
                                        <MenuItem value={0}>No</MenuItem>
                                        <MenuItem value={1}>Yes</MenuItem>
                                    </Select>
                                    </Tooltip>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12}>
                                <FormControl fullWidth variant="outlined">
                                    <InputLabel>Would you say that your general health is:</InputLabel>
                                    <Select
                                        name="GenHlth"
                                        value={formData.GenHlth}
                                        onChange={handleChange}
                                        label="Would you say that your general health is:"
                                    >
                                        <MenuItem value={1}>Excellent</MenuItem>
                                        <MenuItem value={2}>Very Good</MenuItem>
                                        <MenuItem value={3}>Good</MenuItem>
                                        <MenuItem value={4}>Fair</MenuItem>
                                        <MenuItem value={5}>Poor</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12}>
                            <FormControl fullWidth variant="outlined">
                            <Tooltip title="How many days during the past 30 days was your mental health not good?" arrow>
                                <TextField
                                    label="How many days during the past 30 days was your mental health not good?"
                                    type="number"
                                    name="MentHlth"
                                    value={formData.MentHlth}
                                    onChange={handleChange}
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                />
                                </Tooltip>
                            </FormControl>
                            </Grid>

                            <Grid item xs={12}>
                            <FormControl fullWidth variant="outlined">
                            <Tooltip title="How many days during the past 30 days was your physical health not good?" arrow>
                                <TextField
                                    label="How many days during the past 30 days was your physical health not good?"
                                    type="number"
                                    name="PhysHlth"
                                    value={formData.PhysHlth}
                                    onChange={handleChange}
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                />
                                </Tooltip>
                            </FormControl>
                            </Grid>

                            <Grid item xs={12}>
                                <FormControl fullWidth variant="outlined">
                                <Tooltip title="Do you have serious difficulty walking or climbing stairs?" arrow>
                                    <InputLabel>Do you have serious difficulty walking or climbing stairs?</InputLabel>
                                    <Select
                                        name="DiffWalk"
                                        value={formData.DiffWalk}
                                        onChange={handleChange}
                                        label="Do you have serious difficulty walking or climbing stairs?"
                                    >
                                        <MenuItem value={0}>No</MenuItem>
                                        <MenuItem value={1}>Yes</MenuItem>
                                    </Select>
                                    </Tooltip>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12}>
                                <FormControl fullWidth variant="outlined">
                                    <InputLabel>Please select your sex:</InputLabel>
                                    <Select
                                        name="Sex"
                                        value={formData.Sex}
                                        onChange={handleChange}
                                        label="Please select your sex:"
                                    >
                                        <MenuItem value={0}>Female</MenuItem>
                                        <MenuItem value={1}>Male</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12}>
                                <FormControl fullWidth variant="outlined">
                                    <InputLabel>Please select your age category:</InputLabel>
                                    <Select
                                        name="Age"
                                        value={formData.Age}
                                        onChange={handleChange}
                                        label="Please select your age category:"
                                    >
                                        <MenuItem value={1}>18-24</MenuItem>
                                        <MenuItem value={2}>25-29</MenuItem>
                                        <MenuItem value={3}>30-34</MenuItem>
                                        <MenuItem value={4}>35-39</MenuItem>
                                        <MenuItem value={5}>40-44</MenuItem>
                                        <MenuItem value={6}>45-49</MenuItem>
                                        <MenuItem value={7}>50-54</MenuItem>
                                        <MenuItem value={8}>55-59</MenuItem>
                                        <MenuItem value={9}>60-64</MenuItem>
                                        <MenuItem value={10}>65-69</MenuItem>
                                        <MenuItem value={11}>70-74</MenuItem>
                                        <MenuItem value={12}>75-79</MenuItem>
                                        <MenuItem value={13}>80 or older</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12}>
                                <FormControl fullWidth variant="outlined">
                                    <InputLabel>Please select your highest level of education completed:</InputLabel>
                                    <Select
                                        name="Education"
                                        value={formData.Education}
                                        onChange={handleChange}
                                        label="Please select your highest level of education completed:"
                                    >
                                        <MenuItem value={1}>Never attended school or only attended kindergarten</MenuItem>
                                        <MenuItem value={2}>Grades 1 through 8 (Elementary)</MenuItem>
                                        <MenuItem value={3}>Grades 9 through 11 (Some high school)</MenuItem>
                                        <MenuItem value={4}>Grade 12 or GED (High school graduate)</MenuItem>
                                        <MenuItem value={5}>College 1 year to 3 years (Some college or technical school)</MenuItem>
                                        <MenuItem value={6}>College 4 years or more (College graduate)</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12}>
                                <FormControl fullWidth variant="outlined">
                                    <InputLabel>Please select your annual household income:</InputLabel>
                                    <Select
                                        name="Income"
                                        value={formData.Income}
                                        onChange={handleChange}
                                        label="Please select your annual household income:"
                                    >
                                        <MenuItem value={1}>Less than $10,000</MenuItem>
                                        <MenuItem value={2}>$10,000 to $14,999</MenuItem>
                                        <MenuItem value={3}>$15,000 to $19,999</MenuItem>
                                        <MenuItem value={4}>$20,000 to $24,999</MenuItem>
                                        <MenuItem value={5}>$25,000 to $34,999</MenuItem>
                                        <MenuItem value={6}>$35,000 to $49,999</MenuItem>
                                        <MenuItem value={7}>$50,000 to $74,999</MenuItem>
                                        <MenuItem value={8}>$75,000 or more</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <Button variant="contained" color="primary" type="submit" fullWidth>
                                    Submit
                                </Button>
                            </Grid>

                        </Grid>
                    </form>
                </Box>
                </Grid>
                
                
                </Grid>
                
            )}
        </Container>
    );
};

export default DiabetesForm;
