import React, { useState } from 'react';
import axios from 'axios';
import './AdePrediction.css'; // Add a CSS file for custom styles

const AdePrediction = () => {
  const [formData, setFormData] = useState({
    PATIENT: '',
    GENDER: '',
    AGE: '',
    CONDITION_DESCRIPTION: '',
    MEDICATION_DESCRIPTION: '',
  });

  const [adePrediction, setAdePrediction] = useState(null);
  const [adeProbability, setAdeProbability] = useState(null);

  const [costFormData, setCostFormData] = useState({
    Cost_of_ADE: '',
    Cost_of_Prevention: '',
    Preventive_Medication: '',
  });

  const [savings, setSavings] = useState(null);
  const [medicationCost, setMedicationCost] = useState(null);

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleCostInputChange = (e) => {
    setCostFormData({
      ...costFormData,
      [e.target.name]: e.target.value,
    });
  };

//   const handleSubmitPrediction = async (e) => {
//     e.preventDefault();
//     try {
//       const response = await axios.post('https://prod.scribrhealthcare.com/patients/predict', formData);
//       setAdePrediction(response.data.ADE_Prediction);
//       setAdeProbability(response.data.ADE_Probability);
//     } catch (error) {
//       console.error('Error predicting ADE:', error);
//     }
//   };
const handleSubmitPrediction = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('http://localhost:8080/patients/predict', formData);
      setAdePrediction(response.data.ADE_Prediction);
    //   setAdeProbability((response.data.ADE_Probability * 100).toFixed(2)); // Convert to percentage
      setAdeProbability(`${(response.data.ADE_Probability * 100).toFixed(2)}%`); 
    } catch (error) {
      console.error('Error predicting ADE:', error);
    }
  };

  const handleGetMedicationCost = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('http://localhost:8080/patients/calculate-cost', {
        Preventive_Medication: costFormData.Preventive_Medication,
      });
      setMedicationCost(response.data.Total_Cost); // Corrected to match the API response
      setCostFormData({
        ...costFormData,
        Cost_of_Prevention: response.data.Total_Cost,
      });
    } catch (error) {
      console.error('Error fetching medication cost:', error);
    }
  };


  const handleSubmitSavings = async (e) => {
    e.preventDefault();
    if (adePrediction === 1) {
      try {
        const adjustedCostOfAde = (parseFloat(adeProbability) / 100) * parseFloat(costFormData.Cost_of_ADE);

        const response = await axios.post('https://prod.scribrhealthcare.com/patients/calculate-savings', {
          Cost_of_ADE: adjustedCostOfAde,
          Cost_of_Prevention: costFormData.Cost_of_Prevention,
          ADE_Probability: adeProbability,
        });
        setSavings(response.data.Savings);
      } catch (error) {
        console.error('Error calculating savings:', error);
      }
    } else {
      alert('No ADE predicted. No cost savings calculation needed.');
    }
  };

// const handleSubmitSavings = async (e) => {
//     e.preventDefault();
//     if (adePrediction === 1) {
//       try {
//         const response = await axios.post('https://prod.scribrhealthcare.com/patients/calculate-savings', {
//           Preventive_Medication: costFormData.Preventive_Medication,
//         });
  
//         const adjustedCostOfAde = (adeProbability / 100) * parseFloat(costFormData.Cost_of_ADE);
  
//         const savings = adjustedCostOfAde - parseFloat(costFormData.Cost_of_Prevention);
        
//         setSavings(savings);
  
//       } catch (error) {
//         console.error('Error calculating savings:', error);
//       }
//     } else {
//       alert('No ADE predicted. No cost savings calculation needed.');
//     }
//   };
  

  return (
    <div className="container">
      <h2 className="title">ADE Prediction</h2>
      <form className="prediction-form" onSubmit={handleSubmitPrediction}>
        <div className="form-group">
          <label htmlFor="PATIENT">Patient ID:</label>
          <input
            type="text"
            name="PATIENT"
            id="PATIENT"
            placeholder="Enter Patient ID"
            value={formData.PATIENT}
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="GENDER">Gender (M/F):</label>
          <input
            type="text"
            name="GENDER"
            id="GENDER"
            placeholder="Enter Gender"
            value={formData.GENDER}
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="AGE">Age:</label>
          <input
            type="number"
            name="AGE"
            id="AGE"
            placeholder="Enter Age"
            value={formData.AGE}
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="CONDITION_DESCRIPTION">Condition Description:</label>
          <input
            type="text"
            name="CONDITION_DESCRIPTION"
            id="CONDITION_DESCRIPTION"
            placeholder="Enter Condition Description"
            value={formData.CONDITION_DESCRIPTION}
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="MEDICATION_DESCRIPTION">Medication Description:</label>
          <input
            type="text"
            name="MEDICATION_DESCRIPTION"
            id="MEDICATION_DESCRIPTION"
            placeholder="Enter Medication Description"
            value={formData.MEDICATION_DESCRIPTION}
            onChange={handleInputChange}
            required
          />
        </div>
        <button type="submit" className="predict-button">Predict ADE</button>
      </form>

      {adePrediction !== null && (
        <div className="result-section">
          <h3 className="result-title">Prediction Result: {adePrediction === 1 ? 'ADE Predicted' : 'No ADE Predicted'}</h3>
          {adePrediction === 1 && (
            <div className="savings-section">
              <h4>Probability: {adeProbability}</h4>
              <form onSubmit={handleSubmitSavings}>
                <div className="form-group">
                  <label htmlFor="Preventive_Medication">Preventive Medication:</label>
                  <input
                    type="text"
                    name="Preventive_Medication"
                    id="Preventive_Medication"
                    placeholder="Enter Preventive Medications (comma-separated)"
                    value={costFormData.Preventive_Medication}
                    onChange={handleCostInputChange}
                    required
                  />
                  <button className="get-cost-button" onClick={handleGetMedicationCost}>
                    Get Cost
                  </button>
                </div>
                {medicationCost !== null && (
                  <div>
                    <p>Medication Cost: ${medicationCost}</p>
                  </div>
                )}
                <div className="form-group">
                  <label htmlFor="Cost_of_ADE">Cost of ADE:</label>
                  <input
                    type="number"
                    name="Cost_of_ADE"
                    id="Cost_of_ADE"
                    placeholder="Enter Cost of ADE"
                    value={costFormData.Cost_of_ADE}
                    onChange={handleCostInputChange}
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="Cost_of_Prevention">Cost of Prevention:</label>
                  <input
                    type="number"
                    name="Cost_of_Prevention"
                    id="Cost_of_Prevention"
                    placeholder="Enter Cost of Prevention"
                    value={costFormData.Cost_of_Prevention}
                    readOnly
                  />
                </div>
                <button type="submit" className="calculate-button">Calculate Savings</button>
              </form>
            </div>
          )}
        </div>
      )}

      {savings !== null && (
        <div className="savings-message">
          <h3 className="congrats-message">🎉 Congratulations! 🎉</h3>
          <h3 className="savings-result">Potential Savings: ${savings}</h3>
        </div>
      )}
    </div>
  );
};

export default AdePrediction;
