import React from 'react';
import { Container, Typography, Box, Button, Grid } from '@mui/material';
import MailOutlineIcon from '@mui/icons-material/MailOutline';

const InboxManagementPage = () => {
    return (
        <Container maxWidth="lg" style={{ marginTop: '40px' }}>
            <Typography variant="h4" align="center" color="textPrimary" gutterBottom>
                Inbox Management
            </Typography>
            <Typography variant="body1" align="center" color="textSecondary" paragraph>
                Efficiently manage your communications and patient records with our comprehensive inbox management system. Our tool ensures that nothing falls through the cracks, enabling healthcare professionals to focus on providing the best care.
            </Typography>
            
            <Grid container spacing={4} style={{ marginTop: '20px' }}>
                <Grid item xs={12} md={6}>
                    <MailOutlineIcon color="primary" style={{ fontSize: 80, marginBottom: '20px' }} />
                    <Typography variant="h5" color="textPrimary" gutterBottom>
                        Streamlined Communication
                    </Typography>
                    <Typography variant="body1" color="textSecondary" paragraph>
                        Easily manage all your communications in one place. Track patient messages, appointment requests, and other critical communications efficiently.
                    </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                    <MailOutlineIcon color="primary" style={{ fontSize: 80, marginBottom: '20px' }} />
                    <Typography variant="h5" color="textPrimary" gutterBottom>
                        Automated Organization
                    </Typography>
                    <Typography variant="body1" color="textSecondary" paragraph>
                        Our system automatically categorizes and prioritizes messages, ensuring you always address the most urgent matters first.
                    </Typography>
                </Grid>
            </Grid>

            <Box textAlign="center" marginTop="40px">
                <Button variant="contained" color="primary" size="large">
                    Learn More
                </Button>
            </Box>
        </Container>
    );
};

export default InboxManagementPage;
