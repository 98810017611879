import React, { useEffect, useState } from 'react';
import { Button } from '@mui/material';
import logo from './assets/diligent.png';
import Footer from './components/Footer';
const ContactUs = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const mobileStyle = {
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: '20px',
  };

  const desktopStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '10px',
    alignItems: 'center',
    backgroundColor: '#FFFFFF',
    position: 'fixed',
    width: '100%',
    zIndex: 1000,
  };

  return (
    <div style={{backgroundColor: '#F5F5F5', minHeight: '150vh'}}>

    <header style={isMobile ? { ...desktopStyle, ...mobileStyle } : desktopStyle}>
      <img src={logo} alt="Company Logo" height="40" style={{ marginRight: '10px' }} />

      <div>
        <a href="/about-us" style={{ marginRight: '20px',  textDecoration: 'none', fontWeight: '500', color:'#635f5f' }}>About Us</a>
        <a href="/contact-us" style={{ marginRight: '20px', textDecoration: 'none', fontWeight: '500', color:'#635f5f' }}>Contact Us</a>
        <Button variant="contained" color="primary" href="/sign-in" style={{ marginRight: '20px' }}>Sign In</Button>
      </div>
    </header>
    <div style={{ border: '1px solid #ccc', borderRadius: '8px', padding: '100px', maxWidth: '600px', margin: 'auto', boxShadow: '0 2px 4px rgba(0,0,0,0.1)' }}>
      <h1>Contact Us</h1>
      <p>Have a question? Send us an email at: <a href="mailto:info@diligentcares.com">info@diligentcares.com</a></p>
    </div>
    <div style={{ position: 'fixed', width: '100%', bottom: 0, zIndex: 1001 }}>
      <Footer />
    </div>
    </div>
  );
}
export default ContactUs;