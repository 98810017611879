import React from 'react';
import { Box, Container, Typography, Link, Grid } from '@mui/material';

function Footer() {
  return (
    <Box sx={{
        bgcolor: '#D6D6D6',
        p: 6,
        bottom: 0,
        left: 0,
        right: 0,
        width: '100%'
    }} component="footer">
      <Container maxWidth="lg" style={{ marginTop: '20px', padding: '20px 0' }}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={3}>
          <Typography variant="h6">Help Center</Typography>
          <Link to="/help">Visit Help Center</Link>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Typography variant="h6">FAQs</Typography>
          <Link to="/faqs">Frequently Asked Questions</Link>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Typography variant="h6">Blogs</Typography>
          <Link to="/blogs">Read our Blogs</Link>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Typography variant="h6">Videos</Typography>
          <Link to="/videos">Watch Videos</Link>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Typography variant="h6">Terms and Conditions</Typography>
          <a href="/terms-and-conditions">View Terms and Conditions</a>
        </Grid>
      </Grid>
      <Typography variant="body2" color="textSecondary" align="center" style={{ marginTop: '20px' }}>
        © {new Date().getFullYear()} Diligent Cares. All rights reserved.
      </Typography>
    </Container>
    </Box>
  );
}

export default Footer;