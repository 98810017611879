import React from 'react';
import { Container, Grid, Typography, Box, Button, Paper, Divider } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';

const ScribrAI = () => {
    return (
        <Container maxWidth="lg" style={{ marginTop: '40px' }}>
            <Grid container spacing={4} alignItems="center">
                {/* Right Side: Content Section */}
                <Grid item xs={12} md={6}>
                    <Box>
                        <Typography variant="h4" color="textPrimary" gutterBottom>
                            Scribr AI: Revolutionizing Doctor-Patient Interactions
                        </Typography>
                        <Typography variant="body1" color="textSecondary" paragraph>
                            Welcome to Scribr AI, a cutting-edge tool designed to enhance the way doctors and patients communicate. 
                            In the fast-paced world of healthcare, capturing and remembering every detail of a doctor's visit can be challenging. 
                            Scribr AI simplifies this process by automatically generating a comprehensive summary, chat notes, highlights for doctors, 
                            and a personalized recap for patients—all from the recorded conversation between the doctor and patient.
                        </Typography>
                        <Button variant="contained" color="primary" size="large" style={{ marginTop: '20px' }}>
                            Learn More
                        </Button>
                    </Box>
                </Grid>

                {/* Left Side: Key Features Section */}
                <Grid item xs={12} md={6}>
                    <Box mb={4}>
                        <Typography variant="h5" align="center" color="textPrimary" gutterBottom>
                            Transforming Conversations into Actionable Health Insights
                        </Typography>
                        <Divider style={{ margin: '20px 0' }} />
                        <Box display="flex" alignItems="center" mb={2}>
                            <CheckCircleOutlineIcon color="primary" style={{ fontSize: 40, marginRight: 16 }} />
                            <Typography variant="body1">
                                Seamless Recording of doctor-patient conversations.
                            </Typography>
                        </Box>
                        <Box display="flex" alignItems="center" mb={2}>
                            <FitnessCenterIcon color="primary" style={{ fontSize: 40, marginRight: 16 }} />
                            <Typography variant="body1">
                                Intelligent Summarization and generation of chat notes and highlights.
                            </Typography>
                        </Box>
                        <Box display="flex" alignItems="center" mb={2}>
                            <LocalHospitalIcon color="primary" style={{ fontSize: 40, marginRight: 16 }} />
                            <Typography variant="body1">
                                Personalized Recap for patients to review their visit at home.
                            </Typography>
                        </Box>
                    </Box>
                </Grid>
            </Grid>

            <Box mt={4}>
                <Paper elevation={3} style={{ padding: '20px', backgroundColor: '#f9f9f9' }}>
                    <Typography variant="h6" color="textPrimary" gutterBottom>
                        Why Choose Scribr AI?
                    </Typography>
                    <Typography variant="body1" color="textSecondary" paragraph>
                        - <strong>Efficiency:</strong> Save time by automating the documentation process, allowing doctors to focus more on patient care.
                    </Typography>
                    <Typography variant="body1" color="textSecondary" paragraph>
                        - <strong>Accuracy:</strong> Ensure that all important details are captured and easily accessible for both doctors and patients.
                    </Typography>
                    <Typography variant="body1" color="textSecondary" paragraph>
                        - <strong>Patient Empowerment:</strong> Empower patients with clear, understandable recaps of their visits, improving engagement and adherence to treatment plans.
                    </Typography>
                </Paper>
            </Box>
        </Container>
    );
};

export default ScribrAI;
