import React, { useState, useEffect,useRef } from 'react';
import { useParams, useLocation } from 'react-router-dom';

const VisitSummaryPage = () => {
  const { patientId } = useParams();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const visitDate = searchParams.get('visitDate');
  const [visitSummary, setVisitSummary] = useState([]);
  const [textSummaries, setTextSummaries] = useState({});
  const editedTextRef = useRef();
  const [finalizedSummaries, setFinalizedSummaries] = useState({});

  useEffect(() => {
    fetchVisitSummary();
  }, [patientId, visitDate]);

  const fetchVisitSummary = async () => {
    try {
      const [year, month, day] = visitDate.split('-');
      const formattedVisitDate = `${month}-${day}-${year}`;
      const response = await fetch(`https://prod.scribrhealthcare.com/patients/${patientId}/visitSummary?visitDate=${formattedVisitDate}`);
      const data = await response.json();
      if (data && data.data) {
        setVisitSummary(data.data);
      } else {
        setVisitSummary(null);
      }
    } catch (error) {
      console.error('Error fetching visit summary:', error);
    }
  };

  const fetchTextSummary = async (recordingUrl, language = 'en') => {
    try {
      const response = await fetch(`https://prod.scribrhealthcare.com/audio/summary?filePath=${recordingUrl}&lang=${language}`);
      const data = await response.json();

      console.log("Fetched data:", data);
  
      const sections = language === 'es' ? [
        'Información del paciente',
        'Quejas principales',
        'Historial médico',
        'Medicamentos',
        'Plan de tratamiento',
        'Instrucciones de seguimiento'
      ] : [
        'Patient Information',
        'Chief Complaints',
        'Medical History',
        'Medications',
        'Treatment Plan',
        'Follow-up Instructions'
      ];
  
      let localFormattedSummary = '';
      sections.forEach(section => {
        if (data[section] && data[section] !== `${section} not found`) {
          const formattedText = data[section]
          .replace(/\\n/g, '<br/>') // Convert new lines to line breaks
          .replace(/^- /gm, '<li>') // Convert bullet points to list items
          .replace(/<br\/>-/g, '<br/><li>'); // Ensure subsequent bullet points are converted correctly
  
          const wrappedText = formattedText.includes('<li>')
          ? `<ul>${formattedText}</ul>`
          : formattedText;

        localFormattedSummary += `
          <h3 style="margin: 0; padding: 0;">${section}</h3>
          ${wrappedText}
        `;
        }
      });
  
      editedTextRef.current = localFormattedSummary;
      setTextSummaries(prevSummaries => ({
        ...prevSummaries,
        [recordingUrl]: localFormattedSummary
      }));
    } catch (error) {
      console.error('Error fetching text summary:', error);
    }
  };
  const fetchTextSummaryInSpanish = async (recordingUrl) => {
    return fetchTextSummary(recordingUrl, 'es');
  };
  
  const handleFinalizeSummary = async (visitId) => {
    try {
      const response = await fetch('https://prod.scribrhealthcare.com/patients/update/summary', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ visitId, summary: editedTextRef.current })
      });
  
      if (!response.ok) {
        throw new Error('HTTP error ' + response.status);
      }
  
      const data = await response.json();
      setFinalizedSummaries(prevSummaries => ({
        ...prevSummaries,
        [visitId]: data.data.finalSummary
      }));
    } catch (error) {
      console.error('Error finalizing summary:', error);
    }
  };
  
  return (
    <div style={{backgroundColor: 'F5F5F5'}}>
      <br />
      <h2 style={{ marginTop: '60px', color: 'gray', paddingLeft: '30px' }}>Visit Summary</h2>
      <ul>
        {visitSummary && visitSummary.visitSummary && 
          <li>
            <div>Date: {visitSummary.visitSummary.visitDate ? new Date(visitSummary.visitSummary.visitDate.split('-').join('/')).toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' }) : 'N/A'}</div>
            <br />
            {visitSummary.visitSummary.recordingUrl && (
              <div>
                <button  style={{ 
    backgroundColor: '#1E90FF', 
    color: '#FFFFFF', 
    marginRight: '10px',
    height: '40px',
    padding: '10px 20px',
    borderRadius: '5px',
    border: 'none',
    cursor: 'pointer',
    fontSize: '16px',
    transition: 'background-color 0.3s ease'
  }}
  onMouseOver={(e) => e.currentTarget.style.backgroundColor = 'darkblue'}
  onMouseOut={(e) => e.currentTarget.style.backgroundColor = '#1a75ff'} onClick={() => fetchTextSummary(visitSummary.visitSummary.recordingUrl)}>View Text Summary</button>
    
              </div>
            )}
            <div
              contentEditable
              suppressContentEditableWarning
              onBlur={e => editedTextRef.current = e.target.innerHTML}
              style={{ whiteSpace: 'pre-line', paddingLeft: '1.5em', lineHeight: '2' }}
              dangerouslySetInnerHTML={{ __html: textSummaries[visitSummary.visitSummary.recordingUrl] || '' }}
            />
            <div>
              <button onClick={() => handleFinalizeSummary(visitSummary.visitSummary.visitId)}>Finalize Summary</button>
            </div>
  
            <div style={{ whiteSpace: 'pre-line', paddingLeft: '1.5em', lineHeight: '2' }} dangerouslySetInnerHTML={{ __html: finalizedSummaries[visitSummary.visitSummary.visitId] || visitSummary.visitSummary.finalSummary || 'No final summary available' }} />
          </li>
        }
      </ul>
    </div>
  );
  };

export default VisitSummaryPage;