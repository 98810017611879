import { React, useState, useEffect, useRef }  from 'react';
import { Button, Card, CardContent, Typography, Grid } from '@mui/material';
import logo from '../assets/diligent.png';
import Footer from './Footer';// replace with the actual path to your image
import { KeyboardArrowDown } from '@mui/icons-material'; // Import the dropdown icon



function HomePage() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [showDropdown, setShowDropdown] = useState(false);
  const dropdownRef = useRef(null); // Ref for the dropdown to handle click outside


  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleMouseEnter = () => setShowDropdown(true);
  const handleMouseLeave = () => setShowDropdown(false);

  const mobileStyle = {
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: '20px',
  };

  const desktopStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '10px',
    alignItems: 'center',
    backgroundColor: '#FFFFFF',
    position: 'fixed',
    width: '100%',
    zIndex: 1000,
  };

  const linkStyle = {
    marginRight: isMobile ? '0' : '20px',
    textDecoration: 'none',
    fontWeight: '500',
    color: '#635f5f',
    marginBottom: isMobile ? '10px' : '0',
    position: 'relative',
  };

  const productsLinkStyle = {
    ...linkStyle,
    cursor: 'pointer',
    borderBottom: showDropdown ? '2px solid #635f5f' : 'none', // Underline on hover
  };

  const dropdownStyle = {
    position: 'absolute',
    top: '100%',
    left: '0', // Ensure it appears directly under Products
    marginTop: '5px',
    backgroundColor: 'white',
    boxShadow: '0px 8px 16px 0px rgba(0,0,0,0.2)',
    zIndex: 1,
    minWidth: '400px',
    paddingTop: '10px', // Add padding to move the dropdown content down
    display: 'flex', // Flexbox to align content side by side
    justifyContent: 'space-between',
  };

  const dropdownColumnStyle = {
    width: '50%',
    padding: '10px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  };

  const dropdownHeaderStyle = {
    fontWeight: '600',
    fontSize: '16px',
    color: '#333',
    marginBottom: '10px',
    borderBottom: '1px solid #e0e0e0',
    paddingBottom: '7px',
    width: '100%', // Make sure headers take full width to align properly
  };

  const dropdownLinkStyle = {
    padding: '10px',
    display: 'block',
    textDecoration: 'none',
    color: '#635f5f',
    fontWeight: '500',
  };

  return (
    <div style={{backgroundColor: '#F5F5F5', minHeight: '150vh'}}>

    <header style={isMobile ? { ...desktopStyle, ...mobileStyle } : desktopStyle}>
      <img src={logo} alt="Company Logo" height="40" style={{ marginRight: '10px' }} />

      <div style={{ display: 'flex', alignItems: 'center', position: 'relative' }}>
            
      <div
            style={productsLinkStyle}
            onMouseEnter={() => setShowDropdown(true)}
            onMouseLeave={() => setShowDropdown(false)}
            ref={dropdownRef}
          >
            Products
            {showDropdown && (
              <div style={dropdownStyle}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}>
                <div style={dropdownColumnStyle}>
                  <div style={dropdownHeaderStyle}>For Providers & Payors</div>
                  <a href="/ade" style={dropdownLinkStyle}>Adverse Drug Event Prevention</a>
                  <a href="/scribr" style={dropdownLinkStyle}>Scribr AI</a>
                  <a href="/genomic-testing" style={dropdownLinkStyle}>Genomic Testing</a>
                  <a href="/inbox-management" style={dropdownLinkStyle}>Inbox Management</a>
              
                </div>
                <div style={dropdownColumnStyle}>
                  <div style={dropdownHeaderStyle}>For Individuals</div>
                  <a href="/diabetes-predictor" style={dropdownLinkStyle}>Diabetes Predictor</a>
                </div>
              </div>
            )}
          </div>
          <a href="/pricing" style={{ marginRight: '20px',  textDecoration: 'none', fontWeight: '500', color:'#635f5f' }}>Pricing</a>
           <a href="/about-us" style={{ marginRight: '20px',  textDecoration: 'none', fontWeight: '500', color:'#635f5f' }}>About Us</a>
        <a href="/contact-us" style={{ marginRight: '20px', textDecoration: 'none', fontWeight: '500', color:'#635f5f' }}>Contact Us</a>
        <Button variant="contained" color="primary" href="/sign-in" style={{ marginRight: '20px' }}>Sign In</Button>

      </div>
    </header>
    <Grid container>
        <Grid item xs={12} md={6} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', padding: '0 60px' }}>
        <h1 style={{ 
  fontSize: window.innerWidth <= 768 ? '1.5em' : '3.5em', 
  color: '#505050',
  marginTop: window.innerWidth <= 768 ? '120px' : '0' // Adjust '20px' as needed
}}>Concierge-Level Diabetes Care</h1>
          <p style={{ fontSize: '1.2em'}}>Personalized Diabetes Management. Get the Care You Need.</p>
        </Grid>
        <Grid item xs={12} md={6} style={{ marginTop: '60px' }}>
          <img src="https://scribr-assets.s3.us-west-2.amazonaws.com/istock1.jpg" alt="Hero" loading="lazy" style={{ width: '100%', height: 'auto' }} />
        </Grid>
    </Grid>

    <Typography variant="h4" component="h2" style={{ textAlign: 'center', margin: '40px 0 20px 0' }}>Capabilities & Benefits</Typography>

    <Grid container spacing={2} style={{ justifyContent: isMobile ? 'center' : 'flex-start' }}>
    <Grid item xs={12} md={6} lg={3} style={{ display: 'flex', justifyContent: 'center' }}>
    <Card style={{ width: isMobile ? '100%' : '300px', height: '480px', margin: isMobile ? '10px 0' : '10px', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
    <img src="https://scribr-assets.s3.us-west-2.amazonaws.com/monitoring.jpg" alt="Offer 1" style={{ width: '100%', height: '200px' }} loading="lazy" />
            <CardContent>
              <Typography variant="h5" component="div">Real-time Monitoring</Typography>
              <Typography>Forget finger pricks and waiting for appointments. We seamlessly connects with wearables, giving you real-time health data for proactive diabetes management, keeping you informed and empowered to make data-driven decisions about your health.</Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={6} lg={3} style={{ display: 'flex', justifyContent: 'center' }}>
        <Card style={{ width: isMobile ? '100%' : '300px', height: '480px', margin: isMobile ? '10px 0' : '10px', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
        <img src="https://scribr-assets.s3.us-west-2.amazonaws.com/alert.jpg" alt="Offer 2" style={{ width: '100%', height: '200px' }} loading="lazy" />
            <CardContent>
              <Typography variant="h5" component="div">Predictive Health Alerts</Typography>
              <Typography>Uses advanced analytics and integrates individual metabolic and lifestyle data to anticipate potential health issues, send timely alerts for early intervention, and support effective diabetes management. This real-time integration enables precise clinical decisions and promotes better patient care.</Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={6} lg={3} style={{ display: 'flex', justifyContent: 'center' }}>
        <Card style={{ width: isMobile ? '100%' : '300px', height: '480px', margin: isMobile ? '10px 0' : '10px', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
        <img src="https://scribr-assets.s3.us-west-2.amazonaws.com/personalized.jpg" alt="Offer 3" style={{ width: '100%', height: '200px' }} loading="lazy"/>
            <CardContent>
              <Typography variant="h5" component="div">Diligent Care Highlights</Typography>
              <Typography>Imagine a doctor's assistant powered by AI that reads your entire health record and creates clear summaries in real-time. That's Diligent Care. It empowers providers with instant insights from your health journey, leading to better-informed decisions and improved outcomes.</Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={6} lg={3} style={{ display: 'flex', justifyContent: 'center' }}>
        <Card style={{ width: isMobile ? '100%' : '300px', height: '480px', margin: isMobile ? '10px 0' : '10px', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
        <img src="https://scribr-assets.s3.us-west-2.amazonaws.com/genomic2.jpg" alt="Offer 4" style={{ width: '100%', height: '200px' }} loading="lazy" />
            <CardContent>
              <Typography variant="h5" component="div">Genomic Individualization</Typography>
              <Typography>Leverages genetic insights to enhance diabetes management, identifying potential health issues early and optimizing access to genetically informed, personalized treatment plans for comprehensive patient care.</Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <Typography variant="h4" component="h2" style={{ textAlign: 'center', margin: '20px 0' }}>How It Works For Patients</Typography>    
      <div style={{ display: 'flex', justifyContent: 'space-around', flexWrap: 'wrap', padding: '20px' }}>
        <Card style={{ maxWidth: '300px', margin: '10px' }}>
          <img src="https://scribr-assets.s3.us-west-2.amazonaws.com/signup.jpg" alt="Step 1" style={{ width: '100%', height: 'auto' }} loading="lazy" />
          <CardContent>
            <Typography variant="h5" component="div">1. Sign up and create your profile.</Typography>
          </CardContent>
        </Card>
        <Card style={{ maxWidth: '300px', margin: '10px' }}>
          <img src="https://scribr-assets.s3.us-west-2.amazonaws.com/istock3.jpg" alt="Step 2" style={{ width: '100%', height: 'auto' }} loading="lazy" />
          <CardContent>
            <Typography variant="h5" component="div">2. Schedule an appointment with a healthcare provider.</Typography>
          </CardContent>
        </Card>
        <Card style={{ maxWidth: '300px', margin: '10px' }}>
          <img src="https://scribr-assets.s3.us-west-2.amazonaws.com/istock4.jpg" alt="Step 3" style={{ width: '100%', height: 'auto' }} loading="lazy" />
          <CardContent>
            <Typography variant="h5" component="div">3. Attend your appointment virtually or in-person.</Typography>
          </CardContent>
        </Card>
        <Card style={{ maxWidth: '300px', margin: '10px' }}>
          <img src="https://scribr-assets.s3.us-west-2.amazonaws.com/istock5.jpg" alt="Step 4" style={{ width: '100%', height: 'auto' }} loading="lazy" />
          <CardContent>
            <Typography variant="h5" component="div">4. Receive a personalized care plan from your provider.</Typography>
          </CardContent>
        </Card>
        {/* <Card style={{ maxWidth: '300px', margin: '10px' }}>
          <img src={heroImage} alt="Step 5" style={{ width: '100%', height: 'auto' }} />
          <CardContent>
            <Typography variant="h5" component="div">5. Follow your care plan and track your progress on your dashboard.</Typography>
          </CardContent>
        </Card> */}
      </div>
      <div>
      <Typography variant="h4" component="h2" style={{ textAlign: 'left', margin: '20px' }}>
        Take Control of Your Diabetes with Diligent Care
      </Typography>
      <Grid container spacing={4} >
 
  <Grid item xs={12} sm={6}>
    <CardContent>
      <Typography variant="h5" component="h2" style={{ textAlign: 'center', margin: '20px' }}>Precise Diabetes Insights</Typography>
      <Typography variant="body1" component="p" style={{ textAlign: 'center', margin: '20px' }}>
      Receive clear, easy-to-understand insights about your health based on your unique profile, helping you make informed decisions and effectively manage your diabetes.
      </Typography>
    </CardContent>
  </Grid>
  <Grid item xs={12} sm={6}>
    <img src="https://scribr-assets.s3.us-west-2.amazonaws.com/insights.png" alt="Precise Diabetes Insights"  style={{ 
    width: window.innerWidth <= 768 ? '100%' : '50%', 
    height: 'auto' 
  }}  
  loading="lazy" />
  </Grid>

  {/* Row 2 */}
  <Grid item xs={12} sm={6}>
    <CardContent>
      <Typography variant="h5" component="h2" style={{ textAlign: 'center', margin: '20px' }}>Enhanced Access to Care</Typography>
      <Typography variant="body1" component="p" style={{ textAlign: 'center', margin: '20px' }}>
      Diligent Care bridges the gaps in the healthcare system, reducing frustration and enhancing your connection with your healthcare provider, ultimately improving your overall well-being.
      </Typography>
    </CardContent>
  </Grid>
  <Grid item xs={12} sm={6} style={{ display: 'flex', justifyContent: 'flex-end' }}>
    <img src="https://scribr-assets.s3.us-west-2.amazonaws.com/accessCare.png" alt="Enhanced Access to Care"  style={{ 
    width: window.innerWidth <= 768 ? '100%' : '50%', 
    height: 'auto' 
  }}  
  loading="lazy"/>
  </Grid>

  {/* Row 3 */}
  <Grid item xs={12} sm={6}>
    <CardContent>
      <Typography variant="h5" component="h2" style={{ textAlign: 'center', margin: '20px' }}>Clear Post-Visit Recaps</Typography>
      <Typography variant="body1" component="p" style={{ textAlign: 'center', margin: '20px' }}>
      Diligent Care offers detailed summaries of your appointments, including medication identification, text-to-speech audio, multilingual transcription, insurance benefits, and personalized educational toolkits.
      </Typography>
    </CardContent>
  </Grid>
  <Grid item xs={12} sm={6}>
  <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
    {/* <img src="https://scribr-assets.s3.us-west-2.amazonaws.com/recap2.png" alt="Clear Post-Visit Recaps" style={{ width: '50%', height: 'auto' }} loading="lazy" /> */}
    <img 
  src="https://scribr-assets.s3.us-west-2.amazonaws.com/recap4.png" 
  alt="recaps date" 
  style={{ 
    width: window.innerWidth <= 768 ? '100%' : '50%', 
    height: 'auto' 
  }}  
  loading="lazy"
/>  </div>
</Grid>

  {/* Row 4 */}
  <Grid item xs={12} sm={6}>
    <CardContent>
      <Typography variant="h5" component="h2" style={{ textAlign: 'center', margin: '20px' }}>Integrated Health Information</Typography>
      <Typography variant="body1" component="p" style={{ textAlign: 'center', margin: '20px' }}>
      Diligent Care combines all your essential health information from multiple apps and EHR systems into one cohesive platform. This simplifies your diabetes management, making it easier to access and understand your health information.
      </Typography>
    </CardContent>
  </Grid>
  <Grid item xs={12} sm={6} style={{ display: 'flex', justifyContent: 'flex-end' }}>
    <img src="https://scribr-assets.s3.us-west-2.amazonaws.com/integratedHealth.png" alt="Integrated Health Information"  style={{ 
    width: window.innerWidth <= 768 ? '100%' : '50%', 
    height: 'auto' 
  }}  
  loading="lazy" />
  </Grid>
</Grid>

    </div>
    <Footer />
    </div>
  );
}

export default HomePage;