import React from 'react';

const TermsAndConditions = () => {
  return (
    <div>
      <h1 style={{textAlign: 'center'}}>DiligentCares Terms and Conditions</h1>
      <p style={{ margin: '0 20px' }}>These Terms and Conditions ("Terms") govern your use of this website https://diligentcares.com and the services offered by DiligentCares ("DiligentCares," "we," "us," or "our"). By accessing or using the Site, you agree to be bound by these Terms.</p>
        <br></br>
      <p style={{ margin: '0 20px' }}><b>1. Use of the Site</b></p>
      <br></br>
      <p style={{ margin: '0 20px' }}>DiligentCare is a precision care platform designed to support diabetes patients and healthcare providers. The Site provides information, tools, and resources to help manage diabetes effectively. However, the Site is not intended as a substitute for professional medical advice, diagnosis, or treatment. You should always seek the advice of your qualified healthcare provider with any questions you may have regarding a medical condition. </p>
      <br></br>
      <p style={{ margin: '0 20px' }}><b>2.User Content</b></p>
      <br></br>
      <p style={{ margin: '0 20px' }}>You may be able to submit content to the Site, such as comments, reviews, or questions ("User Content"). You are solely responsible for your User Content and represent that you have the right to submit such content. You agree not to submit User Content that is:

Illegal, obscene, defamatory, threatening, harassing, abusive, hateful, or discriminatory.
Infringes on the intellectual property rights of any third party.
Contains viruses or other malicious code.</p>
<br></br>
<p style={{ margin: '0 20px' }}><b>3. Disclaimer of Warranties</b></p>
<br></br>
<p style={{ margin: '0 20px' }}>DiligentCare strives to provide accurate and up-to-date information on the Site. However, the Site and its content are provided "as is" and without warranties of any kind, express or implied. DiligentCare disclaims all warranties, including but not limited to, warranties of merchantability, fitness for a particular purpose, and non-infringement.   

DiligentCare does not warrant that the Site will be uninterrupted or error-free, that defects will be corrected, or that the Site or the server that makes it available

 is free of viruses or other harmful components.</p>
 <br></br>
 <p style={{ margin: '0 20px' }}><b>4. Limitation of Liability</b></p>
 <br></br>
 <p style={{ margin: '0 20px' }}>DiligentCare will not be liable for any damages arising from your use of the Site, including but not limited to, direct, indirect, incidental, consequential, or punitive damages. </p>
 <br></br>
 <p style={{ margin: '0 20px' }}><b>5. Intellectual Property</b></p>
 <br></br>
 <p style={{ margin: '0 20px' }}>The Site and its content are protected by copyright, trademark, and other laws. You may not modify, reproduce, distribute, or create derivative works based on the Site or its content without DiligentCare's written consent.</p>
 <br></br>
 <p style={{ margin: '0 20px' }}><b>6. Termination</b></p> 
 <br></br>
 <p style={{ margin: '0 20px' }}>DiligentCare may terminate your access to the Site at any time, for any reason, without notice.</p>  
 <br></br>
 <p style={{ margin: '0 20px' }}><b>7. Governing Law</b></p>
 <br></br>
 <p style={{ margin: '0 20px' }}>These Terms are governed by the laws of the State of California.</p>
 <br></br>
 <p style={{ margin: '0 20px' }}><b>8. Changes to the Terms</b></p>
 <br></br>
 <p style={{ margin: '0 20px' }}>DiligentCare may modify these Terms at any time. By continuing to use the Site after the changes are made, you agree to be bound by the revised Terms.</p>
 <br></br>
 <p style={{ margin: '0 20px' }}><b>9. Contact Us</b></p>
 <br></br>
 <p style={{ margin: '0 20px' }}>If you have any questions about these Terms, please contact us at info@diligentcares.com.</p>    
 <br></br>
    </div>
  );
};

export default TermsAndConditions;