import React, { useState } from 'react';
import { Drawer, Button, TextField, Box, Typography } from '@mui/material';
import axios from 'axios';

const ContactFormDrawer = ({ open, onClose }) => {
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [description, setDescription] = useState('');
    const [apiResponse, setApiResponse] = useState(null);

    const handleSubmit = async () => {
        if (!email || !phone || !description) {
            setApiResponse('All fields are required.');
            return;
        }

        try {
            const response = await axios.post('https://your-api-endpoint.com/contact', {
                email,
                phone,
                description,
            });

            if (response.status === 200) {
                setApiResponse('Thank you for reaching out! We will contact you soon.');
                setEmail('');
                setPhone('');
                setDescription('');
            } else {
                setApiResponse(`Received non-200 response: ${response.status}`);
            }
        } catch (error) {
            setApiResponse('There was an error submitting the form: ' + error.message);
        }
    };

    return (
        <Drawer anchor="right" open={open} onClose={onClose}>
            <Box width={300} padding={2}>
                <Typography variant="h6" gutterBottom>
                    Get In Touch
                </Typography>
                <TextField
                    label="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    fullWidth
                    margin="normal"
                />
                <TextField
                    label="Phone"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    fullWidth
                    margin="normal"
                />
                <TextField
                    label="Description"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    fullWidth
                    multiline
                    rows={4}
                    margin="normal"
                />
                {apiResponse && (
                    <Typography color="error" variant="body2" gutterBottom>
                        {apiResponse}
                    </Typography>
                )}
                <Button variant="contained" color="primary" onClick={handleSubmit} fullWidth>
                    Submit
                </Button>
            </Box>
        </Drawer>
    );
};

export default ContactFormDrawer;
